interface Props extends React.HTMLAttributes<HTMLDivElement> {
	children?: React.ReactNode
	className?: string
}

export default function Center({ children, className, ...props }: Props) {
	return (
		<div
			{...props}
			className={`flex items-center justify-center ${className}`}
		>
			{children}
		</div>
	)
}
