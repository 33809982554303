export default function IconBox({
	children,
	className,
	onClick,
}: {
	children: React.ReactNode
	className?: string
	onClick?: () => void
}) {
	return (
		<div
			className={`cursor-pointer w-fit h-fit p-1 rounded border-[1px] border-[#F0F0F0] bg-white hover:bg-[#F0F0F0] ${className}`}
			onClick={onClick}
		>
			{children}
		</div>
	)
}
