export default function ResizeIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
		>
			<rect width="16" height="16" fill="white" />
			<path
				d="M13.354 8.35366L8.35403 13.3537C8.30757 13.4001 8.25242 13.437 8.19173 13.4621C8.13103 13.4872 8.06598 13.5002 8.00028 13.5002C7.93458 13.5002 7.86953 13.4872 7.80883 13.4621C7.74813 13.437 7.69298 13.4001 7.64653 13.3537C7.60007 13.3072 7.56322 13.252 7.53808 13.1914C7.51294 13.1307 7.5 13.0656 7.5 12.9999C7.5 12.9342 7.51294 12.8692 7.53808 12.8085C7.56322 12.7478 7.60007 12.6926 7.64653 12.6462L12.6465 7.64615C12.693 7.5997 12.7481 7.56285 12.8088 7.53771C12.8695 7.51257 12.9346 7.49963 13.0003 7.49963C13.066 7.49963 13.131 7.51257 13.1917 7.53771C13.2524 7.56285 13.3076 7.5997 13.354 7.64615C13.4005 7.69261 13.4373 7.74776 13.4625 7.80846C13.4876 7.86915 13.5006 7.93421 13.5006 7.99991C13.5006 8.0656 13.4876 8.13066 13.4625 8.19135C13.4373 8.25205 13.4005 8.3072 13.354 8.35366ZM12.354 2.14615C12.3076 2.09967 12.2524 2.06279 12.1917 2.03763C12.131 2.01246 12.066 1.99951 12.0003 1.99951C11.9346 1.99951 11.8695 2.01246 11.8088 2.03763C11.7481 2.06279 11.693 2.09967 11.6465 2.14615L2.14653 11.6462C2.05271 11.74 2 11.8672 2 11.9999C2 12.1326 2.05271 12.2598 2.14653 12.3537C2.24035 12.4475 2.3676 12.5002 2.50028 12.5002C2.63296 12.5002 2.76021 12.4475 2.85403 12.3537L12.354 2.85365C12.4005 2.80722 12.4374 2.75207 12.4626 2.69138C12.4877 2.63068 12.5007 2.56561 12.5007 2.4999C12.5007 2.4342 12.4877 2.36913 12.4626 2.30843C12.4374 2.24774 12.4005 2.19259 12.354 2.14615Z"
				fill="#6D6D6D"
			/>
		</svg>
	)
}
