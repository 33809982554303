import { Position, useReactFlow } from "@xyflow/react"
import Center from "components/center"
import Handle from "../subcomponents/Handle"
import { IoCodeSlashOutline } from "react-icons/io5"
import { Button } from "components/button"
import {
	ButtonColor,
	ButtonIconPlacement,
	ButtonVariant,
} from "components/button/types"
import { TbAdjustmentsHorizontal, TbEye } from "react-icons/tb"
import PlayIcon from "icons/PlayIcon"
import { BsBraces } from "react-icons/bs"
import { RefObject, useEffect, useRef, useState } from "react"
import { openConfigAtom, variablesAtom } from "../atoms"
import { useAtom } from "jotai"
import ActionNodeHandler from "../subcomponents/ActionNodeHandler"
import { Node, NodeData } from "../subcomponents/Flow"
import AutosizeInput from "react-input-autosize"
import VariablesModal from "../subcomponents/VariablesModal"
import LoadingSpinner from "pages/MyDocumentsPage/subcomponents/LoadingSpinner"
import NodeError from "../subcomponents/NodeError"
import NodeSuccess from "../subcomponents/NodeSuccess"
import { useSaveNode } from "../hooks/useSaveNode"
import useNodeExecutionStatus from "../hooks/useNodeExecutionStatus"
import useAgentBuilder from "hooks/useAgentBuilder"
import { useMutation } from "@tanstack/react-query"
import CustomResizable from "components/custom-resizable"

export default function Prompt({ data, id }: { data: NodeData; id: string }) {
	const [showOutput, setShowOutput] = useState(true)
	const [nodeName, setNodeName] = useState(data.nodeData.name)
	const [editTitle, setEditTitle] = useState(false)
	const [openVariablesModal, setOpenVariablesModal] = useState(false)
	const [, setOpenConfig] = useAtom(openConfigAtom)
	const { nodeData, graphId } = data
	const { setNodes, getNodes, getNode } = useReactFlow()
	const nodes = getNodes()
	const currentNode = getNode(id) as Node | undefined
	const { nodeExecutionStatus, setNodeExecutionStatus } =
		useNodeExecutionStatus(id)
	const inputRef: RefObject<AutosizeInput> &
		(string | RefObject<HTMLInputElement>) = useRef(null)

	const { nodeExecutionStart } = useAgentBuilder()
	const [variables] = useAtom(variablesAtom)

	const nodeExecutionMutation = useMutation({
		mutationFn: nodeExecutionStart,
		onSuccess: (data) => {
			if (data) {
				setOpenVariablesModal(false)
			}
		},
	})

	const onRename = () => {
		setEditTitle(true)
	}

	const onSaveTitle = () => {
		const newNodes = nodes.map((node) => {
			if (node.id === id) {
				return {
					...node,
					data: {
						...node.data,
						nodeData: {
							...(typeof node.data.nodeData === "object"
								? node.data.nodeData
								: {}),
							name: nodeName,
						},
					},
				}
			}
			return node
		})
		setNodes(newNodes)
		setEditTitle(false)
	}

	const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === "Enter") {
			event.preventDefault()
			onSaveTitle()
		}
	}

	function handleChangePrompt(e: React.ChangeEvent<HTMLTextAreaElement>) {
		const newNodes = nodes.map((node) => {
			if (node.id === id) {
				return {
					...node,
					data: {
						...node.data,
						nodeData: {
							...(typeof node.data.nodeData === "object"
								? node.data.nodeData
								: {}),
							prompt: e.target.value,
						},
					},
				}
			}
			return node
		})
		setNodes(newNodes)
	}

	function getPromptValue() {
		if (nodeData?.prompt && nodeData?.prompt !== "prompt") {
			return nodeData.prompt
		}
		return ""
	}

	async function onExecute() {
		const placeholders = extractPlaceholders()

		if (!placeholders || Object.keys(placeholders).length === 0) {
			await nodeExecutionMutation.mutateAsync({
				input_state: {},
				node: id,
			})
		} else {
			setOpenVariablesModal(true)
		}
	}

	function extractPlaceholders(currentVariables?: Record<string, string>) {
		if (!currentNode) return
		const regex = /{([^}]+)}/g
		const newVariables: Record<string, string> = {}
		const existingVariables = currentVariables
			? { ...currentVariables }
			: {}
		let match: RegExpExecArray | null

		while (
			(match = regex.exec(currentNode.data.nodeData.prompt)) !== null
		) {
			const key = match[1]

			const variableInState = variables?.find(
				(v) => v.name === `{${key}}`,
			)

			newVariables[key] =
				variableInState?.value || existingVariables[key] || ""
		}

		const filteredVariables = Object.keys(newVariables).reduce(
			(acc, key) => {
				acc[key] = newVariables[key]
				return acc
			},
			{} as Record<string, string>,
		)
		return filteredVariables
	}

	function onResizeNode(width: number, height: number) {
		const newNodes = nodes.map((node) => {
			if (node.id === id) {
				return {
					...node,
					size: {
						width,
						height,
					},
				}
			}
			return node
		})
		setNodes(newNodes)
	}

	useEffect(() => {
		if (editTitle && inputRef.current) {
			inputRef.current.select()
		}
	}, [editTitle])

	useSaveNode({
		currentNode,
		graphId,
		id,
	})

	return (
		<>
			<ActionNodeHandler
				id={id}
				onRename={onRename}
				isError={
					!!(
						nodeExecutionStatus &&
						nodeExecutionStatus.status === "ERROR"
					)
				}
			>
				<div className="min-w-[400px] p-2 flex flex-col gap-2 bg-white border-[1px] border-brand-white-4 rounded-2xl h-full ">
					<Handle type="target" position={Position.Left} />
					<Handle type="source" position={Position.Right} />

					<div className="flex items-center justify-between bg-[#E8DEEE] p-2 pr-[20px] rounded-lg">
						<div className="  flex items-center text-[#AF6BEF] font-semibold  gap-3">
							<Center className="w-[48px] h-[48px] p-2 bg-white rounded">
								<IoCodeSlashOutline
									size="32px"
									color="#AF6BEF"
								/>
							</Center>

							<div className="flex flex-col ">
								<p className="text-small-1">Prompt</p>
								{!editTitle ? (
									<h4 className=" text-[#AF6BEF] max-w-[178px] truncate">
										{nodeName || "prompt_name"}
									</h4>
								) : (
									<AutosizeInput
										inputClassName="text-[#AF6BEF] p-0  m-0 h-[27px]  text-h4 font-semibold border-none bg-transparent  focus-visible:!outline-none max-w-[178px] overflow-hidden text-ellipsis whitespace-nowrap"
										value={nodeName || "prompt_name"}
										onChange={(event) =>
											setNodeName(event.target.value)
										}
										onBlur={onSaveTitle}
										autoFocus
										ref={inputRef}
										onKeyDown={handleKeyDown}
										maxLength={50}
									/>
								)}
							</div>
						</div>

						<div className="flex items-center gap-2 fill-white">
							<Button
								variant={ButtonVariant.Contained}
								color={ButtonColor.SecondaryV2}
								className="text-small-1 !w-[73px] !h-[24px] !p-[4px]"
								icon={<TbAdjustmentsHorizontal size="16px" />}
								iconPlacement={ButtonIconPlacement.End}
								onClick={() => setOpenConfig(id)}
							>
								GPT-4o
							</Button>
							<Button
								variant={ButtonVariant.Contained}
								color={ButtonColor.SecondaryV2}
								className="text-small-1 !w-fit !h-[24px] !p-[4px]"
								icon={<PlayIcon size="16" />}
								iconPlacement={ButtonIconPlacement.End}
								onClick={onExecute}
							/>
						</div>
					</div>

					<CustomResizable
						onResizeStop={(_, __, elementRef) => {
							onResizeNode(
								elementRef.clientWidth,
								elementRef.clientHeight,
							)
						}}
						defaultSize={{
							width: currentNode?.size.width,
							height: currentNode?.size.height,
						}}
					>
						<div className="p-4 bg-brand-white-2 rounded-lg h-full">
							<div className="flex items-center justify-between w-full mb-3">
								<p className="text-cta-2">Input</p>
								<Center className="p-[4px] rounded border-[1px] border-[#F0F0F0] ">
									<BsBraces size="16px" color="#6D6D6D" />
								</Center>
							</div>

							<textarea
								className="nodrag  resize-none p-3 rounded-lg border-[#F0F0F0] placeholder:text-[#B0B0B0] min-h-[104px] h-[calc(100%-34px)] w-full"
								placeholder="Digite seu comando"
								value={getPromptValue()}
								onChange={(e) => handleChangePrompt(e)}
							/>
						</div>
					</CustomResizable>

					{nodeExecutionStatus &&
						nodeExecutionStatus.status !== "ERROR" && (
							<div className="p-4 bg-brand-white-2 rounded-lg ">
								<div
									className={`flex items-center justify-between w-full  ${
										showOutput && "mb-3"
									}`}
								>
									<p className="text-cta-2">Output</p>
									<Center
										className="p-[4px] rounded border-[1px] border-[#F0F0F0] nodrag cursor-pointer"
										onClick={() =>
											setShowOutput(!showOutput)
										}
									>
										<TbEye size="16px" color="#6D6D6D" />
									</Center>
								</div>

								{showOutput && (
									<div className="relative">
										{nodeExecutionStatus?.status ===
											"START" && (
											<div className=" absolute inset-0 flex items-center justify-center">
												<div className=" opacity-50 bg-white w-full h-full" />
												<div className="absolute">
													<LoadingSpinner />
												</div>
											</div>
										)}
										<textarea
											className="nodrag w-full resize-y p-3 rounded-lg border-[#F0F0F0] placeholder:text-[#B0B0B0] min-h-[104px]"
											value={
												nodeExecutionStatus?.result
													?.result || ""
											}
											onChange={() => {}}
											disabled={
												nodeExecutionStatus?.status ===
												"START"
											}
										/>
									</div>
								)}
							</div>
						)}

					{nodeExecutionStatus &&
						nodeExecutionStatus.status === "ERROR" && <NodeError />}

					{nodeExecutionStatus &&
						nodeExecutionStatus.status === "SUCCESS" && (
							<NodeSuccess
								nodeExecutionStatus={nodeExecutionStatus}
							/>
						)}

					<VariablesModal
						open={openVariablesModal}
						setOpen={setOpenVariablesModal}
						nodeId={id}
						setNodeExecution={setNodeExecutionStatus}
						extractPlaceholders={extractPlaceholders}
						nodeExecutionMutation={nodeExecutionMutation}
					/>
				</div>
			</ActionNodeHandler>
		</>
	)
}
