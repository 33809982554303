const PlayIcon = ({ size = "24" }: { size?: string }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="current"
		>
			<path
				d="M6.63636 19.2727C6.46759 19.2727 6.30573 19.2057 6.18639 19.0864C6.06705 18.967 6 18.8052 6 18.6364V4.63641C5.99999 4.52582 6.0288 4.41714 6.08359 4.32108C6.13838 4.22502 6.21726 4.14489 6.31245 4.0886C6.40764 4.03231 6.51586 4.0018 6.62643 4.00008C6.73701 3.99835 6.84613 4.02547 6.94303 4.07877L19.6703 11.0788C19.7701 11.1337 19.8534 11.2144 19.9114 11.3125C19.9694 11.4105 20 11.5224 20 11.6364C20 11.7503 19.9694 11.8622 19.9114 11.9603C19.8534 12.0584 19.7701 12.1391 19.6703 12.194L6.94303 19.194C6.84907 19.2456 6.74359 19.2727 6.63636 19.2727Z"
				fill=""
			/>
		</svg>
	)
}

export default PlayIcon
