import { parseISO, differenceInMilliseconds } from 'date-fns';

export const calculateExecutionTime = (startTime: string, endTime: string) => {
  if (!startTime || !endTime) return 0;
  const start = parseISO(startTime);
  const end = parseISO(endTime);

  const diffInMilliseconds = differenceInMilliseconds(end, start);
  // const diffInSeconds = diffInMilliseconds / 1000;

  return diffInMilliseconds
};

