import Center from "components/center"
import { useState } from "react"
import ReactTextareaAutosize from "react-textarea-autosize"
import { TbUpload } from "react-icons/tb"
import { Variables } from "../types"
import InputField from "components/input-field"
import ClickAwayListener from "react-click-away-listener"

export default function VariableValues({
	currentVariable,
	setVariables,
	variables,
	currentIndex,
}: {
	currentVariable: Variables
	setVariables: (variable: Variables[]) => void
	variables: Variables[]
	currentIndex: number
}) {
	const [focused, setFocused] = useState(false)

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.files) {
			const newVariables = [...variables]
			newVariables[currentIndex].value = [
				...newVariables[currentIndex].value,
				...event.target.files,
			]
			setVariables(newVariables)
		}
	}

	if (currentVariable.type === "FILE") {
		return (
			<div className="relative">
				<label
					htmlFor="file-upload"
					className="w-[156px] h-[44px] mt-3 nodrag bg-white rounded-md border-[1px] border-brand-gray-1 flex items-center px-3 cursor-pointer"
				>
					<p className="text-cta-2 text-[#B0B0B0] ">Upload</p>
				</label>
				<input
					id="file-upload"
					type="file"
					multiple
					accept=".pdf,.docx"
					className="hidden"
					onChange={handleFileChange}
				/>
				<Center
					className="w-[44px] h-[44px] bg-[#FCFCFC] absolute top-0 right-0 rounded-r-md border-[1px] border-brand-gray-1 nodrag cursor-pointer"
					onChange={handleFileChange}
				>
					<label htmlFor="file-upload">
						<TbUpload color="#6D6D6D" size="16px" />
					</label>
				</Center>
			</div>
		)
	}

	if (currentVariable.type === "NUMBER") {
		return (
			<InputField
				placeholder="Valor"
				className="w-[156px] h-[44px] mt-3 nodrag truncate"
				value={currentVariable.value}
				onChange={(e) => {
					const newVariables = [...variables]
					newVariables[currentIndex].value = e.target.value
					setVariables(newVariables)
				}}
				type="number"
			/>
		)
	}

	return (
		<ClickAwayListener onClickAway={() => setFocused(false)}>
			<div className="relative">
				{!focused ? (
					<InputField
						placeholder="Valor"
						className="w-[156px] h-[44px] mt-3 nodrag truncate"
						value={currentVariable.value}
						onChange={() => {}}
						onFocus={() => setFocused(true)}
					/>
				) : (
					<ReactTextareaAutosize
						placeholder="Valor"
						className={` z-20 absolute top-0 min-h-[44px] left-0 max-h-[470px] w-[156px] text-cta-2 placeholder:absolute placeholder:top-[9px]   mt-3 nodrag !outline-offset-0 block rounded-md border !border-1 resize-none focus:!outline-none border-brand-gray-1 focus:border focus:border-light-blue-4  placeholder:text-[#B0B0B0]`}
						onBlur={() => {
							setFocused(false)
						}}
						autoFocus
						value={currentVariable.value}
						onChange={(e) => {
							const newVariables = [...variables]
							newVariables[currentIndex].value = e.target.value
							setVariables(newVariables)
						}}
						onKeyDown={(e) => {
							if (e.key === "Enter" && !e.shiftKey) {
								e.preventDefault()
								setFocused(false)
							}
						}}
					/>
				)}
			</div>
		</ClickAwayListener>
	)
}
