import Center from "components/center"
import { LuClock7 } from "react-icons/lu"
import { TbCircleCheckFilled, TbCurrencyDollar } from "react-icons/tb"
import { NodeExecutionResponse } from "types/agentBuilder"
import { formatCost } from "utils/formatCost"
import { getExecutionTime } from "utils/getExecutionTime"

export default function NodeSuccess({
	nodeExecutionStatus,
}: {
	nodeExecutionStatus: NodeExecutionResponse
}) {
	return (
		<div className="p-4 bg-[#FCFCFC] rounded-lg flex items-center justify-between text-cta-2 font-semibold">
			<div className="flex items-center gap-1">
				<TbCircleCheckFilled size="20px" color="#00862E" />
				<p>Sucesso</p>
			</div>
			<div className="flex items-center gap-2">
				<Center className="rounded p-1 gap-1  border-[1px] border-[#ECF5FF] bg-[#F5F5F5]">
					<LuClock7 size="16px" />
					<p>
						{getExecutionTime(
							nodeExecutionStatus?.start_time,
							nodeExecutionStatus?.end_time,
						)}
					</p>
				</Center>

				<Center className="rounded p-1 gap-1  border-[1px] border-[#ECF5FF] bg-[#F5F5F5]">
					<TbCurrencyDollar size="16px" />
					<p>{formatCost(nodeExecutionStatus?.cost || 0)}</p>
				</Center>
			</div>
		</div>
	)
}
