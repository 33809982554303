import { useMutation } from "@tanstack/react-query"
import { AxiosError } from "axios"
import { showToast } from "components/toast/functions"
import { ToastType } from "components/toast/types"
import useCurrentUser from "hooks/useCurrentUser"
import useLegalDocumentAPI from "hooks/useLegalDocumentAPI"
import UploadIcon from "icons/UploadIcon"
import { useAtom } from "jotai"
import { plansModalAtom } from "pages/BasePage/atoms"
import FileCard from "pages/DocumentPage/subcomponents/FileCard"
import { useEffect, useRef, useState } from "react"
import { RetrieveLegalDocumentResponse } from "types/legalDocument"
export interface FileState {
	id: string
	name: string
	size: number
	status?: string
}

export default function FileDropzone({
	data,
	documentId,
}: {
	data: RetrieveLegalDocumentResponse
	documentId: string
}) {
	const [files, setFiles] = useState<FileState[]>([])
	const inputFileRef = useRef<HTMLInputElement>(null)
	const user = useCurrentUser()
	const [, setIsOpenPlansModal] = useAtom(plansModalAtom)

	const { uploadFileToDocument } = useLegalDocumentAPI()
	const uploadDocumentMutation = useMutation({
		mutationFn: uploadFileToDocument,
		onError: (error: AxiosError) => {
			if (error.response?.status === 400) {
				showToast(
					"Arquivo com excesso de texto. Por favor, tente outro.",
					ToastType.Error,
				)
			} else if (error.response?.status === 413) {
				showToast(
					"Arquivo muito grande. Anexe até 15MB em arquivos.",
					ToastType.Error,
				)
			} else {
				showToast(
					"Um erro inesperado aconteceu. Por favor, tente novamente.",
					ToastType.Error,
				)
			}
		},
	})

	function handleStorageFull() {
		if (user.subscription.product_name === "Profissional") {
			showToast(
				"Armazenamento cheio. Remova arquivos para liberar espaço de armazenamento.",
				ToastType.Error,
			)
		} else {
			showToast(
				"Armazenamento cheio. Faça upgrade ou remova arquivos para liberar espaço de armazenamento.",
				ToastType.Error,
			)
			setIsOpenPlansModal(true)
		}
	}

	const processFiles = async (selectedFiles: File[]) => {
		const totalSizeNewFiles = selectedFiles.reduce((a, b) => a + b.size, 0)
		const totalSizeOldFiles = files.reduce((a, b) => a + b.size, 0)

		if (user.total_storage_usage >= user.subscription.max_upload_size) {
			handleStorageFull()
			return
		}

		if (totalSizeNewFiles + totalSizeOldFiles > 15728640) {
			showToast(
				"O total dos arquivos anexados não pode exceder 15MB",
				ToastType.Error,
			)
			return
		}

		const newFiles = selectedFiles.map((file) => ({
			id: "",
			name: file.name.split(".")[0],
			size: file.size,
			status: "uploading",
		}))

		setFiles([...files, ...newFiles])

		let newFilesState = [...newFiles]

		for (const file of selectedFiles) {
			const formData = new FormData()
			formData.append("file_path", file)
			try {
				await uploadDocumentMutation.mutateAsync(
					{ id: documentId, payload: formData },
					{
						onSuccess(data) {
							newFilesState = newFilesState.map((item) => {
								if (item.name === file.name.split(".")[0]) {
									return {
										id: data.id,
										name: file.name.split(".")[0],
										size: file.size,
										status: "uploaded",
									}
								}
								return item
							})
						},
					},
				)
			} catch (error) {
				newFilesState = newFilesState.map((item) => {
					if (item.name === file.name.split(".")[0]) {
						return {
							id: "",
							name: file.name.split(".")[0],
							size: file.size,
							status: "error",
						}
					}
					return item
				})
			}
		}
		setFiles([...files, ...newFilesState])
	}

	const onDrop = async (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault()
		const selectedFiles = Array.from(event.dataTransfer.files)
		await processFiles(selectedFiles)
	}

	function onDragOver(event: React.DragEvent<HTMLDivElement>) {
		event.preventDefault()
	}

	function onDragEnter(event: React.DragEvent<HTMLDivElement>) {
		event.preventDefault()
	}

	function onDragLeave(event: React.DragEvent<HTMLDivElement>) {
		event.preventDefault()
	}
	function handleDeleteFile(index: number) {
		setFiles(files?.filter((_, ind) => ind !== index))
	}
	function handleDivClick() {
		if (user) {
			if (user.total_storage_usage >= user.subscription.max_upload_size) {
				if (user.subscription.product_name === "Profissional") {
					showToast(
						"Armazenamento cheio. Remova arquivos para liberar espaço de armazenamento.",
						ToastType.Error,
					)
				} else {
					showToast(
						"Armazenamento cheio. Faça upgrade ou remova arquivos para liberar espaço de armazenamento.",
						ToastType.Error,
					)
					setIsOpenPlansModal(true)
				}
				return
			}
		}
		if (inputFileRef.current) {
			inputFileRef.current.click()
		}
	}

	const onFileChange = async (event: any) => {
		const selectedFiles: File[] = Array.from(event.target.files)
		await processFiles(selectedFiles)
		event.target.value = ""
	}

	useEffect(() => {
		if (
			data &&
			data?.attached_documents &&
			data?.attached_documents?.length > 0
		) {
			setFiles(() => {
				const newData = []
				for (const document of data.attached_documents || []) {
					const fileAlredyExists = files.find(
						(file) => file.id === document.id,
					)
					if (!fileAlredyExists) {
						newData.push({
							id: document.id,
							name: document.file_name,
							size: document.file_size,
							status: "uploaded",
						})
					}
				}
				return [...files, ...newData]
			})
		}
	}, [data?.attached_documents?.length])

	return (
		<div className="flex gap-4 w-full flex-wrap ">
			<div className="flex flex-col h-[200px] mb-6">
				<div
					className={`flex items-center flex-col bg-white border-2 rounded text-center shadow-outlined  border-dashed cursor-pointer  w-[174px] h-[152px]`}
					onClick={handleDivClick}
					onDragOver={onDragOver}
					onDragEnter={onDragEnter}
					onDragLeave={onDragLeave}
					onDrop={onDrop}
				>
					<div className="flex flex-col text-center items-center p-3.5 my-1 h-full hover:bg-semi-white-4">
						<div className="bg-semi-white-4 w-[32px] h-[32px] rounded-full mb-6">
							<UploadIcon />
						</div>

						<input
							type="file"
							className="hidden"
							onChange={onFileChange}
							ref={inputFileRef}
							accept=".pdf, .txt, .docx"
							multiple
						></input>
						<p className="text-brand-gray-2 text-cta-2 w-[134px] leading-5">
							Clique ou arraste aqui para{" "}
							<span className="text-dark-blue-1 text-cta-2 leading-5">
								fazer upload
							</span>
						</p>
					</div>
				</div>

				<span className="text-center text-brand-gray-2 text-[12px] leading-5 mt-2">
					Anexe até 15MB em arquivos
				</span>
			</div>

			{files?.map((file, index) => {
				return (
					<FileCard
						key={index}
						documentId={documentId}
						error={file.status === "error"}
						file={file}
						isUploading={file.status === "uploading"}
						onDelete={() => handleDeleteFile(index)}
					/>
				)
			})}
		</div>
	)
}
