import Modal from "components/modal"

import { useReactFlow } from "@xyflow/react"
import { Node } from "./Flow"
import { useEffect, useState } from "react"
import { Button } from "components/button"
import { ButtonVariant } from "components/button/types"
import { UseMutationResult } from "@tanstack/react-query"
import { NodeExecutionPayload, NodeExecutionResponse } from "types/agentBuilder"

export default function VariablesModal({
	open,
	setOpen,
	nodeId,
	extractPlaceholders,
	nodeExecutionMutation,
}: {
	open: boolean
	setOpen: React.Dispatch<React.SetStateAction<boolean>>
	nodeId: string
	setNodeExecution: React.Dispatch<
		React.SetStateAction<NodeExecutionResponse | null>
	>
	extractPlaceholders: (
		currentVariables: Record<string, string>,
	) => Record<string, string> | undefined
	nodeExecutionMutation: UseMutationResult<
		NodeExecutionResponse,
		unknown,
		NodeExecutionPayload,
		unknown
	>
}) {
	const { getNode } = useReactFlow()
	const [variables, setVariables] = useState<Record<string, string>>({})
	const currentNode = getNode(nodeId) as Node | undefined

	async function onConfirm() {
		await nodeExecutionMutation.mutateAsync({
			input_state: variables,
			node: nodeId,
		})
	}

	useEffect(() => {
		if (!currentNode) return
		const placeholders = extractPlaceholders(variables)

		if (placeholders) {
			setVariables(placeholders)
		}
	}, [currentNode, open])

	return (
		<Modal
			openModal={open}
			setOpenModal={setOpen}
			title="Preencher variáveis"
			width="w-[550px] p-8 overflow-visible max-h-[70vh] overflow-y-auto"
			titleClassName="!text-[20px] text-[#3D3D3D]"
		>
			<div className="mt-6 flex flex-col gap-6">
				{Object.entries(variables).map(([key, value]) => (
					<div key={key} className="flex flex-col gap-3">
						<p className="text-[#3D3D3D] text-cta-2 font-semibold">
							{key}
						</p>
						<textarea
							value={value}
							onChange={(e) => {
								setVariables({
									...variables,
									[key]: e.target.value,
								})
							}}
							className="w-full p-2 border-[1px] border-[#F0F0F0] rounded-lg min-h-[104px]"
						/>
					</div>
				))}
			</div>

			<div className="w-full flex items-center justify-between gap-4 mt-8">
				<Button
					variant={ButtonVariant.Outlined}
					onClick={() => setOpen(false)}
					className="w-full  justify-center"
				>
					Cancelar
				</Button>

				<Button
					variant={ButtonVariant.Contained}
					className="w-full  justify-center"
					onClick={onConfirm}
				>
					Confirmar
				</Button>
			</div>
		</Modal>
	)
}
