import Center from "components/center"
import { GoAlertFill } from "react-icons/go"
import { LuClock7 } from "react-icons/lu"
import { TbCurrencyDollar } from "react-icons/tb"
import { FaPlay } from "react-icons/fa"
import useAgentBuilder from "hooks/useAgentBuilder"
import { useMutation } from "@tanstack/react-query"

import { graphExecutionAtom, variablesAtom } from "../atoms"
import { useAtom } from "jotai"
import { getExecutionTime } from "utils/getExecutionTime"
import { formatCost } from "utils/formatCost"

export default function GraphLog({ graphId }: { graphId: string }) {
	const [variables] = useAtom(variablesAtom)
	const { graphExecutionStart } = useAgentBuilder()
	const [graphExecution] = useAtom(graphExecutionAtom)
	const graphExecutionStartMutation = useMutation({
		mutationFn: graphExecutionStart,
	})

	function transformVariablesTosend() {
		const transformedObject = variables.reduce(
			(acc: { [key: string]: any }, item) => {
				const key = item.name.replace(/[{}]/g, "")
				acc[key] = item.value
				return acc
			},
			{} as { [key: string]: any },
		)
		return transformedObject
	}

	return (
		<Center className="gap-4 absolute top-[72px] right-4">
			{graphExecution?.status === "ERROR" && (
				<Center className="rounded p-1 gap-1 bg-red-opacity h-[24px]">
					<div className="text-light-red">
						<GoAlertFill size="16px" />
					</div>
					<p className="font-semibold text-small-1">Erro</p>
				</Center>
			)}

			<Center className="rounded p-1 gap-1  border-[1px] border-[#ECF5FF] bg-[#F5F5F5] h-[24px]">
				<LuClock7 size="16px" />
				<p className="font-semibold text-small-1">
					{getExecutionTime(
						graphExecution?.start_time,
						graphExecution?.end_time,
					)}
				</p>
			</Center>

			<Center className="rounded p-1 gap-1  border-[1px] border-[#ECF5FF] bg-[#F5F5F5] h-[24px]">
				<TbCurrencyDollar size="16px" />
				<p className="font-semibold text-small-1">
					{formatCost(graphExecution?.cost || 0)}
				</p>
			</Center>
			<Center
				className="rounded p-1 gap-1  border-[1px] border-[#ECF5FF] bg-white w-[36px] h-[36px] text-light-blue-4 cursor-pointer "
				onClick={() =>
					graphExecutionStartMutation.mutate({
						graph: graphId,
						input_variables: transformVariablesTosend(),
					})
				}
			>
				<FaPlay size="16px" />
			</Center>
		</Center>
	)
}
