import { IoIosArrowBack } from "react-icons/io"
import { useNavigate } from "react-router-dom"
import { RefObject, useEffect, useRef, useState } from "react"
import TitleTooltip from "components/title-tooltip"
import AutosizeInput from "react-input-autosize"
import HistoryIcon from "icons/HistoryIcon"
import { Button } from "components/button"
import { ButtonColor, ButtonVariant } from "components/button/types"
import useAgentBuilder from "hooks/useAgentBuilder"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import ClickAwayListener from "react-click-away-listener"
import { GraphCheckpoint, GraphData } from "types/agentBuilder"
import PromptTag from "pages/AgentBuilderPage/subcomponents/PromptTag"

export default function GraphHeader({
	setOpenStepper,
	graphId,
	graph,
	checkpoints,
}: {
	setOpenStepper: (value: boolean) => void
	graphId: string
	graph: GraphData | undefined
	checkpoints: GraphCheckpoint[] | undefined
}) {
	const navigate = useNavigate()
	const [isEdit, setIsEdit] = useState(false)
	const [inputTitle, setInputTitle] = useState(graph?.name || "")
	const inputRef: RefObject<AutosizeInput> &
		(string | RefObject<HTMLInputElement>) = useRef(null)
	const { editGraph, addGraphCheckpoint, publishGraph } = useAgentBuilder()

	const queryClient = useQueryClient()

	const editGraphMutation = useMutation({
		mutationFn: editGraph,
	})

	const addGraphCheckpointMutation = useMutation({
		mutationFn: addGraphCheckpoint,
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ["checkpoints", graphId],
			})
			queryClient.invalidateQueries({
				queryKey: ["graph", graphId],
			})
		},
	})

	const publishGraphMutation = useMutation({
		mutationFn: publishGraph,
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ["checkpoints", graphId],
			})
			queryClient.invalidateQueries({
				queryKey: ["graph", graphId],
			})
		},
	})

	useEffect(() => {
		if (isEdit && inputRef.current) {
			inputRef.current.select()
		}
	}, [isEdit])

	const onSaveTitle = async () => {
		editGraphMutation.mutateAsync({
			id: graphId,
			name: inputTitle,
		})
		setIsEdit(false)
	}

	const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === "Enter") {
			event.preventDefault()
			onSaveTitle()
		}
	}

	const handleCreateCheckpoint = () => {
		addGraphCheckpointMutation.mutate(graphId)
	}

	const handlePublishCheckpoint = () => {
		publishGraphMutation.mutate({
			graphId,
		})
	}

	return (
		<div className="h-[52px] flex items-center justify-between gap-4 px-4 py-2 bg-white border-[1px] border-brand-gray-1   w-full z-50">
			<div className="flex items-center gap-2">
				<div
					className="cursor-pointer fill-dark-blue-1 hover:fill-dark-blue-2"
					onClick={() => navigate("/agent-builder")}
				>
					<IoIosArrowBack size="20px" fill="current" />
				</div>
				<ClickAwayListener
					onClickAway={() => {
						if (isEdit) {
							onSaveTitle()
						}
					}}
				>
					<div className="flex items-center ">
						{!isEdit ? (
							<div className="flex items-center ">
								<TitleTooltip text={inputTitle || ""}>
									<h1
										onClick={() => setIsEdit(true)}
										className={`font-semibold mr-[2px] text-dark-blue-1 max-w-[calc(100vw-600px)] w-fit overflow-hidden text-ellipsis whitespace-nowrap text-cta-1`}
									>
										{inputTitle || "Novo agente"}
									</h1>
								</TitleTooltip>
							</div>
						) : (
							<AutosizeInput
								inputClassName="text-dark-blue-1 p-0 m-0 text-cta-1 font-semibold border-none bg-transparent  focus-visible:!outline-none max-w-[calc(100vw-600px)] overflow-hidden text-ellipsis whitespace-nowrap"
								value={inputTitle || ""}
								onChange={(event) =>
									setInputTitle(event.target.value)
								}
								onBlur={onSaveTitle}
								autoFocus
								ref={inputRef}
								onKeyDown={handleKeyDown}
							/>
						)}
					</div>
				</ClickAwayListener>
				<div className="ml-2">
					{graph && <PromptTag graph={graph} />}
				</div>
			</div>

			<div className="flex items-center gap-4">
				<Button
					variant={ButtonVariant.Outlined}
					className=" !h-[36px] !p-2  flex items-center justify-center"
					color={ButtonColor.PrimaryV2}
					onClick={() => setOpenStepper(true)}
					disabled={!checkpoints || checkpoints?.length === 0}
				>
					<HistoryIcon />
				</Button>
				<Button
					variant={ButtonVariant.Outlined}
					className=" !h-[36px] !p-[3] w-[80px] flex items-center justify-center"
					color={ButtonColor.PrimaryV2}
					onClick={handleCreateCheckpoint}
				>
					Salvar
				</Button>
				<Button
					variant={ButtonVariant.Contained}
					className=" !h-[36px] !p-[3] !text-[14px] w-[80px] flex items-center justify-center"
					onClick={handlePublishCheckpoint}
				>
					Publicar
				</Button>
			</div>
		</div>
	)
}
