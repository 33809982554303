export function generateRandomName() {
  const alphabet = 'abcdefghijklmnopqrstuvwxyz';

  let randomLetters = '';
  for (let i = 0; i < 3; i++) {
    const randomIndex = Math.floor(Math.random() * alphabet.length);
    randomLetters += alphabet[randomIndex];
  }

  return `${randomLetters}`;
}

