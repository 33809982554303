import { Handle as DefaultHandle, HandleProps } from "@xyflow/react"

export default function Handle({ ...props }: HandleProps) {
	return (
		<DefaultHandle
			style={{
				width: "16px",
				height: "16px",
				backgroundColor: "white",
				border: "1px solid #E7E7E7",
			}}
			{...props}
		/>
	)
}
