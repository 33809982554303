import UpdateChangesIcon from "icons/UpdateChangesIcon"
import { Graph, GraphData } from "types/agentBuilder"

export default function PromptTag({ graph }: { graph: Graph | GraphData }) {
	function getPromptConfig() {
		if (!graph.last_publication) {
			return {
				color: "bg-[#F3F7F9] text-[#646464]",
				name: "Draft",
				icon: null,
			}
		}

		if (graph.last_checkpoint && graph.last_publication) {
			const lastCheckpoint = new Date(graph.last_checkpoint)
			const lastPublication = new Date(graph.last_publication)

			if (lastCheckpoint >= lastPublication) {
				return {
					color: "bg-[#FFF5EE] text-[#E87725]",
					name: "Publicado",
					icon: <UpdateChangesIcon />,
				}
			}

			if (lastCheckpoint <= lastPublication) {
				return {
					color: "bg-[#E4FFEC] text-[#068F2D]",
					name: "Publicado",
					icon: null,
				}
			}
		}

		return {
			color: "bg-[#F3F7F9] text-[#646464]",
			name: "Draft",
			icon: null,
		}
	}

	const promptConfig = getPromptConfig()

	return (
		<div
			className={`p-1 flex items-center justify-center  rounded w-[100px] ${promptConfig?.color} gap-2`}
		>
			<p className="font-semibold text-cta-2">{promptConfig?.name}</p>
			{promptConfig?.icon && promptConfig.icon}
		</div>
	)
}
