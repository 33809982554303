import { useFeatureFlag } from "hooks/useFeatureFlag"
import Flow from "./subcomponents/Flow"
import { ReactFlowProvider } from "@xyflow/react"
import FEATURE_FLAGS from "feature-flags"
import { Navigate } from "react-router-dom"

export default function GraphPage() {
	const { data: isAdminUserFeatureFlagEnabled } = useFeatureFlag(
		FEATURE_FLAGS.isAdminUser,
	)

	if (
		!isAdminUserFeatureFlagEnabled &&
		isAdminUserFeatureFlagEnabled !== undefined
	)
		return <Navigate to="/" />

	return (
		<ReactFlowProvider>
			<Flow />
		</ReactFlowProvider>
	)
}
