export default function UpdateChangesIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="17"
			height="16"
			viewBox="0 0 17 16"
			fill="none"
		>
			<path
				d="M12.1078 2H13.5V1H10V4H11V2.5481C13.1177 3.5154 14.5 5.62425 14.5 8C14.5 11.3083 11.8084 14 8.5 14V15C12.3598 15 15.5 11.8598 15.5 8C15.5 5.4956 14.1841 3.2439 12.1078 2Z"
				fill="#E87725"
			/>
			<path
				d="M8.5 10C8.0858 10 7.75 10.3358 7.75 10.75C7.75 11.1642 8.0858 11.5 8.5 11.5C8.9142 11.5 9.25 11.1642 9.25 10.75C9.25 10.3358 8.9142 10 8.5 10ZM8 4.5H9V9H8V4.5Z"
				fill="#E87725"
			/>
			<path
				d="M8.5 2V1C4.64015 1 1.5 4.14015 1.5 8C1.5 10.4883 2.8037 12.7469 4.8921 14H3.5V15H7V12H6V13.4516C3.8683 12.4738 2.5 10.3587 2.5 8C2.5 4.69165 5.19165 2 8.5 2Z"
				fill="#E87725"
			/>
		</svg>
	)
}
