import { GraphNode } from "types/agentBuilder"
import { Node } from "../subcomponents/Flow"

interface Ranges {
	nodeId: string
	llm: string
	ranges: {
		title: string
		max: number
		value: number
		id: string
	}[]
}
export function serializeNode(node: Node, graphId: string, ranges?: Ranges) {
	const commonProperties = {
		id: node.id,
		name: node.data.nodeData.name || "",
		pos_x: node.position.x,
		pos_y: node.position.y,
		graph: graphId,
		width: node.size.width,
		height: node.size.height,
		output_format: "TEXT",
	}

	const getRangeValue = (rangeId: string, defaultValue = 0) =>
		ranges?.ranges.find((range) => range.id === rangeId)?.value ||
		defaultValue

	const nodeConfigurations: Record<string, object> = {
		parser: {
			node_type: "PARSER",
			parser_id: node.data.nodeData.parser || "comma_parser",
		},
		prompt: {
			node_type: "PROMPT",
			prompt: node.data.nodeData.prompt || "prompt",
			temperature: getRangeValue("temperature"),
			top_p: getRangeValue("top_p"),
			frequency_penalty: getRangeValue("frequency_penalty"),
			presence_penalty: getRangeValue("presence_penalty"),
			max_tokens: getRangeValue("max_tokens"),
			llm_model: ranges?.llm || "gpt-4o-mini-2024-07-18",
		},
		variables: {
			node_type: "START",
			output_format: node.data.nodeData.inputVariable || "TEXT",
		},
		preview: {
			node_type: "END",
		},
		default: {
			node_type: "TOOL",
			parser_id: "merge_list_parser",
		},
	}

	const nodeConfig =
		nodeConfigurations[node.type] || nodeConfigurations.default

	return {
		...commonProperties,
		...nodeConfig,
	}
}

export function sanitizeNode(nodes: GraphNode[]): Node[] {
	const getCommonProperties = (node: GraphNode) => ({
		id: node.id,
		position: { x: node.pos_x, y: node.pos_y },
		size: { width: node.width, height: node.height },
		data: { graphId: node.graph },
	})

	const nodeConfigurations: Record<
		string,
		(node: GraphNode) => Partial<Node>
	> = {
		PARSER: (node) => ({
			type: "parser",
			data: {
				...getCommonProperties(node).data,
				nodeData: {
					parser: node.parser_id,
					name: node.name,
				},
			},
		}),
		PROMPT: (node) => ({
			type: "prompt",
			data: {
				...getCommonProperties(node).data,
				nodeData: {
					prompt: node.prompt,
					name: node.name,
				},
			},
		}),
		START: (node) => ({
			type: "variables",
			data: {
				...getCommonProperties(node).data,
				nodeData: { name: node.name },
			},
		}),
		END: (node) => ({
			type: "preview",
			data: {
				...getCommonProperties(node).data,
				nodeData: { name: node.name },
			},
		}),
		default: (node) => ({
			type: "tool",
			data: {
				...getCommonProperties(node).data,
				nodeData: { name: node.name },
			},
		}),
	}

	return nodes.map((node) => {
		const config =
			nodeConfigurations[node.node_type] || nodeConfigurations.default
		const sanitizedNode = {
			...getCommonProperties(node),
			...config(node),
		}

		if (!sanitizedNode.type) {
			throw new Error(`Node type is missing for node with ID: ${node.id}`)
		}

		return sanitizedNode as Node
	})
}

export const typesTranslate = {
	variables: "Variáveis",
	prompt: "Prompt",
	jurisprudence: "Jurisprudência",
	parser: "Parser",
	preview: "Preview",
}

export const nodesDefaultSizes = {
	prompt: { width: 400, height: 145 },
	variables: { width: 200, height: 100 },
	parser: { width: 200, height: 100 },
	preview: { width: 640, height: 640 },
}
