import { TbAlertTriangleFilled } from "react-icons/tb"

export default function NodeError() {
	return (
		<div className="p-4 bg-[#FFF1F1] brand-white-2 rounded-lg">
			<div className="flex items-center gap-1">
				<TbAlertTriangleFilled size="20px" color="#FF6363" />
				<p className="text-cta-2">Erro</p>
			</div>

			<div className="p-3 rounded-lg border-[1px] border-[F0F0F0] bg-white mt-3">
				<p className="text-cta-2">
					Sed egestas aliquet egestas dictum habitasse enim. Sit sit
					vivamus dolor eu mattis quis. Eleifend molestie consequat
					dui egestas dui nunc dui rhoncus bibendum. Nisl aliquet nisl
				</p>
			</div>
		</div>
	)
}
