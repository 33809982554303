import ResizeIcon from "icons/ResizeIcon"
import { Resizable, ResizableProps } from "re-resizable"

export default function CustomResizable(props: ResizableProps) {
	return (
		<Resizable
			defaultSize={{
				width: 400,
			}}
			minWidth={400}
			minHeight={180}
			className="nodrag"
			handleComponent={{
				bottomRight: <ResizeIcon />,
			}}
			enable={{
				bottomRight: true,
			}}
			{...props}
		>
			{props.children}
		</Resizable>
	)
}
