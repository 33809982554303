import React, { useState } from "react"
import { useAtom } from "jotai"
import { typeAtom } from "../atoms"
import Center from "components/center"
import PlusIcon from "icons/PlusIcon"
import { BsBraces } from "react-icons/bs"
import { TbFileInvoice } from "react-icons/tb"
import { TbListSearch } from "react-icons/tb"
import { TbTransform } from "react-icons/tb"
import { IoCodeSlashOutline } from "react-icons/io5"
import { NodeTypes } from "../types"

interface DragEventWithTransfer extends React.DragEvent {
	dataTransfer: DataTransfer
}

function Card({
	icon,
	title,
	description,
	className,
	onDragStart,
	draggable,
	setOpen,
}: {
	icon: React.ReactNode
	title: string
	description: string
	className?: string
	onDragStart?: (event: DragEventWithTransfer) => void
	draggable?: boolean
	setOpen: React.Dispatch<React.SetStateAction<boolean>>
}) {
	return (
		<div
			className={`p-3 border-[1px] border-brand-white-4 rounded-lg w-[238px] cursor-pointer ${className}`}
			onDragStart={onDragStart}
			draggable={draggable}
			onDragEnd={() => setOpen(false)}
		>
			<div className="flex items-center gap-2">
				<div>{icon}</div>
				<p className="text-cta-1 font-semibold">{title}</p>
			</div>
			<p className="mt-1 text-cta-2">{description}</p>
		</div>
	)
}

export default function Sidebar() {
	const [, setType] = useAtom(typeAtom)
	const [open, setOpen] = useState(false)

	const onDragStart = (
		event: DragEventWithTransfer,
		nodeType: NodeTypes,
	): void => {
		setType(nodeType)
		event.dataTransfer.effectAllowed = "move"
	}

	return (
		<aside className="absolute top-[72px] left-8 z-50">
			<Center
				className="w-[48px] h-[48px] text-white bg-light-blue-4 rounded-full cursor-pointer hover:bg-[#1B64F5] hover:shadow-blue-button"
				onClick={() => setOpen(!open)}
			>
				<PlusIcon size="32px" />
			</Center>
			{open && (
				<div className="bg-white  flex flex-col  mt-4 w-[536px] p-6 rounded-lg shadow-3 gap-6">
					<div>
						<h4 className="mb-3">Sistema</h4>
						<div className="flex gap-3">
							<Card
								setOpen={setOpen}
								title="Input de variáveis"
								description="Define os parâmetros iniciais ou dinâmicos utilizados pelos nodes"
								icon={<BsBraces size="24px" color="#D37C3D" />}
								className="opacity-50 !cursor-default"
							/>
							<Card
								setOpen={setOpen}
								title="Preview do output"
								description="Define o resultado final do output do agente"
								icon={
									<TbFileInvoice
										size="24px"
										color="#D37C3D"
									/>
								}
								onDragStart={(event) =>
									onDragStart(event, "preview")
								}
								draggable
							/>
						</div>
					</div>
					<div>
						<h4 className="mb-3">Ações</h4>
						<div className="flex gap-3">
							<Card
								setOpen={setOpen}
								title="Buscar jurisprudência"
								description="Realiza consulta de jurisprudências no banco de dados"
								icon={
									<TbListSearch size="24px" color="#3D88B4" />
								}
								onDragStart={(event) =>
									onDragStart(event, "tool")
								}
								draggable
							/>
							<Card
								setOpen={setOpen}
								title="Parser"
								description="Converte output para outro formato esperado"
								icon={
									<TbTransform size="24px" color="#3D88B4" />
								}
								onDragStart={(event) =>
									onDragStart(event, "parser")
								}
								draggable
							/>
						</div>
					</div>
					<div>
						<h4 className="mb-3">Interação com LLMs</h4>
						<div className="flex gap-3">
							<Card
								setOpen={setOpen}
								title="Prompt"
								description="Gera respostas ou orientações com base no input recebido"
								icon={
									<IoCodeSlashOutline
										size="24px"
										color="#AF6BEF"
									/>
								}
								onDragStart={(event) =>
									onDragStart(event, "prompt")
								}
								draggable
							/>
						</div>
					</div>
					{/* <div
						className="dndnode input"
						onDragStart={(event) => onDragStart(event, "input")}
						draggable
					>
						Input Node
					</div>
					<div
						className="dndnode"
						onDragStart={(event) => onDragStart(event, "default")}
						draggable
					>
						Default Node
					</div>
					<div
						className="dndnode output"
						onDragStart={(event) => onDragStart(event, "output")}
						draggable
					>
						Output Node
					</div> */}
				</div>
			)}
		</aside>
	)
}
