import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import Tooltip from "components/tooltip"
import ExclamationIcon from "icons/ExclamationIcon"
import { useAtom } from "jotai"
import { useEffect, useRef, useState } from "react"
import { getDocumentStatus } from "utils/getDocumentStatus"
import { openLexAtom } from "pages/SidebarPages/subcomponents/atoms"
import { TooltipPosition } from "components/tooltip/enums"
import { LegalDocumentStatus, LegalDocumentType } from "types/legalDocument"
import DocumentInformationModal from "../DocumentInformationModal"

import { useNavigate } from "react-router-dom"
import { ROUTES } from "routes"

import NewDocumentTextArea from "components/document-text-area"
import { WS_SERVER } from "config"
import { useJwt } from "hooks/useJwt"
import ModelIcon from "icons/ModelIcon"
import useLegalDocumentAPI from "hooks/useLegalDocumentAPI"
import useFreePlan from "hooks/useFreePlan"
import { plansModalAtom } from "pages/BasePage/atoms"

export function NewDocumentComplete({
	documentId,
	type,
}: {
	documentId: string
	type: LegalDocumentType
}) {
	const { createLegalDocument, retrieveLegalDocument } = useLegalDocumentAPI()
	const isFreeUser = useFreePlan()
	const [, setIsOpen] = useAtom(plansModalAtom)

	const { data } = useQuery(
		["legalDocument", documentId],
		() => {
			return retrieveLegalDocument(documentId!)
		},
		{
			staleTime: Infinity,
		},
	)
	const [openLex] = useAtom(openLexAtom)
	const [openInformations, setOpenInformations] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [jwt] = useJwt()

	const navigate = useNavigate()
	const queryClient = useQueryClient()
	const wsRef = useRef<WebSocket>()

	const createPetitionMutation = useMutation({
		mutationFn: () =>
			createLegalDocument({
				legal_document_type: data?.legal_document_type || "TEMPLATE",
				template_id: data?.id || null,
				template_type: "DOCUMENT",
				title: ``,
			}),
		onSuccess: (data) => {
			navigate(ROUTES.documentDetail({ id: data.id }), {
				state: { documentAsModel: true },
			})
		},
	})

	useEffect(() => {
		queryClient.invalidateQueries({
			queryKey: ["retrieveLegalDocument", documentId],
		})
		queryClient.invalidateQueries(["legalDocumentType", documentId])
		queryClient.invalidateQueries(["legalDocument", documentId])
	}, [])

	useEffect(() => {
		const generateContentProcess =
			data?.processes &&
			data?.processes.find(
				(process) => process.process_type === "generate_content",
			)
		if (
			!generateContentProcess?.id ||
			getDocumentStatus(data) !== LegalDocumentStatus.Processing
		)
			return
		setIsLoading(true)
		wsRef.current = new WebSocket(
			`${WS_SERVER.baseUrl}/doc-stream?token=${jwt}&process_id=${generateContentProcess?.id}`,
		)
		wsRef.current.onopen = () => {
			console.log("ws open")
			queryClient.invalidateQueries({
				queryKey: ["retrieveLegalDocument", documentId],
			})
		}
		wsRef.current.onmessage = (e) => {
			if (e.data === "ping") {
				wsRef.current?.send("pong")

				return
			}
		}

		wsRef.current.onerror = () => {
			console.log("ws error")
			setIsLoading(false)
		}

		wsRef.current.onclose = () => {
			console.log("ws close")
			queryClient.invalidateQueries({
				queryKey: ["legalDocument", documentId],
			})
			queryClient.invalidateQueries({
				queryKey: ["retrieveLegalDocument", documentId],
			})
			queryClient.invalidateQueries(["legalDocumentType", documentId])

			setIsLoading(false)
		}

		return () => {
			wsRef.current?.close(1000, "finished")
		}
	}, [data?.processes, documentId])

	function onCreateModel() {
		if (isFreeUser) {
			setIsOpen(true)
			return
		} else {
			createPetitionMutation.mutate()
		}
	}

	if (isLoading) {
		return (
			<div className="bg-white">
				<div className="mb-6 p-[48px]">
					<div
						role="status"
						className="animate-pulse flex flex-col gap-2"
					>
						<div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-full mb-0 mt-0"></div>
						<div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-full mb-0 mt-0"></div>
						<div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-[80%] mb-0 mt-0"></div>
					</div>
				</div>
			</div>
		)
	}

	return (
		<div className={`flex ${!openLex ? "w-full" : "w-[calc(100%-324px)]"}`}>
			<div className="w-full">
				<div className="flex">
					<div className="w-full">
						{type === LegalDocumentType.Uploaded ? (
							<div className="flex justify-between items-center w-full mb-8">
								<h3 className="font-semibold text-text-dark-primary">
									Meu documento
								</h3>
							</div>
						) : (
							<div className="flex items-center justify-between">
								<p>
									Documento gerado com sucesso. Copie ou salve
									abaixo:
								</p>
								<div className="flex items-center ">
									<Tooltip
										text="Informações do documento"
										bg={"bg-dark-blue-3"}
										position={TooltipPosition.Below}
										className="right-[86px]"
									>
										<div
											onClick={() =>
												setOpenInformations(true)
											}
										>
											<ExclamationIcon className="cursor-pointer" />
										</div>
									</Tooltip>
									<Tooltip
										text="Usar como modelo"
										className="right-[60px]"
										position={TooltipPosition.Below}
									>
										<div
											className={` cursor-pointer ml-6 ${
												getDocumentStatus(data) !==
												"completed"
													? "text-brand-gray-2"
													: "text-dark-blue-1 hover:text-dark-blue-2"
											}`}
											onClick={() => onCreateModel()}
										>
											<ModelIcon className="fill-current" />
										</div>
									</Tooltip>
								</div>
							</div>
						)}
						<div className={"mt-8"}>
							{data && data.content && data?.content.text && (
								<NewDocumentTextArea
									id={data?.id}
									type={data?.type}
									text={data?.content.text}
									status={getDocumentStatus(data)}
									data-cy="document-text-area"
								/>
							)}

							{data && data.text && data?.text?.text && (
								<NewDocumentTextArea
									id={data?.id}
									type={data?.type}
									text={data?.text.text}
									status={getDocumentStatus(data)}
									data-cy="document-text-area"
								/>
							)}
						</div>
					</div>
				</div>
			</div>
			<DocumentInformationModal
				setOpenModal={setOpenInformations}
				openModal={openInformations}
				title={data?.title || ""}
				userInput={data?.user_input}
				toFavor={data?.to_favor}
				type={data?.type}
				specificType={data?.legal_document_type}
			/>
		</div>
	)
}
