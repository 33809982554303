export enum ButtonIconPlacement {
	Start = "start",
	End = "end",
}

export enum ButtonVariant {
	Contained = "contained",
	Outlined = "outlined",
	Text = "text",
}

export enum ButtonColor {
	Primary = "primary",
	PrimaryV2 = "primaryV2",
	SecondaryV2 = "secondaryV2",
	Secondary = "secondary",
}
