const TagFile2 = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="33"
			height="41"
			viewBox="0 0 33 41"
			fill="none"
		>
			<path
				d="M12 0.999999L12 16C12 18.7614 14.2386 21 17 21L32 21"
				stroke="#F0F0F0"
				strokeLinecap="round"
			/>
		</svg>
	)
}

export default TagFile2
