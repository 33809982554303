import { Button } from "components/button"
import { ButtonVariant } from "components/button/types"
import Modal from "components/modal"
import { Node } from "./Flow"
import useAgentBuilder from "hooks/useAgentBuilder"
import { useMutation } from "@tanstack/react-query"
import { useReactFlow } from "@xyflow/react"
import ExclusionButton from "components/exclusion-button"
import { useAtom } from "jotai"
import { rangesAtom } from "../atoms"

export default function DeleteNodeModal({
	open,
	setOpen,
	node,
	setNode,
}: {
	open: boolean
	setOpen: React.Dispatch<React.SetStateAction<boolean>>
	node: Node
	setNode: React.Dispatch<React.SetStateAction<Node | null>>
}) {
	const { deleteNode } = useAgentBuilder()
	const deleteNodeMutation = useMutation({
		mutationFn: deleteNode,
	})
	const { setNodes, getNodes } = useReactFlow()
	const [ranges, setRanges] = useAtom(rangesAtom)

	async function handelDeleteNode() {
		await deleteNodeMutation.mutateAsync(node.id)
		const currentNodes = getNodes()
		const newNodes = currentNodes.filter((n) => n.id !== node.id)
		const newRanges = ranges.filter((range) => range.nodeId !== node.id)

		setNodes(newNodes)
		setRanges(newRanges)
		setNode(null)
		setOpen(false)
	}
	return (
		<Modal
			openModal={open}
			setOpenModal={setOpen}
			title="Excluir node"
			width="w-[550px] p-8 overflow-visible"
		>
			<div className="mt-6">
				<p className="text-brand-gray-3 mb-1 ">
					Deseja realmente excluir o node “{node.data.nodeData.name}”?
					Esta ação é irreversível.
				</p>

				<div className="w-full flex items-center justify-between gap-4 mt-8">
					<Button
						variant={ButtonVariant.Outlined}
						onClick={() => setOpen(false)}
						className="w-full  justify-center"
					>
						Cancelar
					</Button>

					<ExclusionButton
						className="w-full"
						onClick={() => handelDeleteNode()}
					>
						Excluir
					</ExclusionButton>
				</div>
			</div>
		</Modal>
	)
}
