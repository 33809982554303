import { useState } from "react"
import ClickAwayListener from "react-click-away-listener"

export default function VariableDropDown({
	children,
	items,
	onChange,
	variableIndex,
}: {
	children: React.ReactNode
	items: { label: string; value: string }[]
	onChange: (value: string, index: number) => void
	variableIndex: number
}) {
	const [open, setOpen] = useState(false)
	return (
		<div className="relative">
			<div onClick={() => setOpen(!open)}>{children}</div>
			{open && (
				<ClickAwayListener
					onClickAway={() => {
						setOpen(false)
					}}
				>
					<div className="absolute top-8 left-0 bg-white z-50 shadow-3 rounded-lg w-[120px] ">
						{items.map((item, index) => (
							<div
								key={index}
								className={`hover:bg-[#F5F5F5] flex cursor-pointer items-center px-[8px] py-[8px] duration-200 ease-out `}
								onClick={() => {
									onChange(item.value, variableIndex)
									setOpen(false)
								}}
							>
								<div className="flex items-center justify-between w-full">
									<span className={`ml-[8px] text-cta-2`}>
										{item.label}
									</span>
								</div>
							</div>
						))}
					</div>
				</ClickAwayListener>
			)}
		</div>
	)
}
