import { Position, useReactFlow } from "@xyflow/react"
import Center from "components/center"
import Handle from "../subcomponents/Handle"
import { Button } from "components/button"
import {
	ButtonColor,
	ButtonIconPlacement,
	ButtonVariant,
} from "components/button/types"
import PlayIcon from "icons/PlayIcon"
import { TbEye, TbListSearch } from "react-icons/tb"
import { RefObject, useEffect, useRef, useState } from "react"
import ActionNodeHandler from "../subcomponents/ActionNodeHandler"
import { NodeData } from "../subcomponents/Flow"
import AutosizeInput from "react-input-autosize"

export default function JurisprudenceSearch({
	data,
	id,
}: {
	data: NodeData
	id: string
}) {
	const [showOutput, setShowOutput] = useState(true)
	const [nodeName, setNodeName] = useState(data.nodeData.name)
	const [editTitle, setEditTitle] = useState(false)
	const { setNodes, getNodes } = useReactFlow()
	const nodes = getNodes()
	const inputRef: RefObject<AutosizeInput> &
		(string | RefObject<HTMLInputElement>) = useRef(null)

	useEffect(() => {
		if (editTitle && inputRef.current) {
			inputRef.current.select()
		}
	}, [editTitle])

	const onRename = () => {
		setEditTitle(true)
	}

	const onSaveTitle = () => {
		const newNodes = nodes.map((node) => {
			if (node.id === id) {
				return {
					...node,
					data: {
						...node.data,
						nodeData: {
							...(typeof node.data.nodeData === "object"
								? node.data.nodeData
								: {}),
							name: nodeName,
						},
					},
				}
			}
			return node
		})
		setNodes(newNodes)
		setEditTitle(false)
	}

	const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === "Enter") {
			event.preventDefault()
			onSaveTitle()
		}
	}

	return (
		<ActionNodeHandler id={id} onRename={onRename}>
			<div className="w-[400px] p-2 flex flex-col gap-2 bg-white border-[1px] border-brand-white-4 rounded-2xl">
				<Handle type="target" position={Position.Left} />
				<Handle type="source" position={Position.Right} />

				<div className="flex items-center justify-between bg-[#D3E5EF] p-2 pr-[20px] rounded-lg">
					<div className="  flex items-center text-[#3D88B4] font-semibold  gap-3">
						<Center className="w-[48px] h-[48px] p-2 bg-white rounded">
							<TbListSearch size="32px" color="#3D88B4" />
						</Center>

						<div className="flex flex-col ">
							<p className="text-small-1">
								Busca de jurisprudência
							</p>
							{!editTitle ? (
								<h4 className=" text-[#3D88B4] max-w-[248px] truncate">
									{nodeName || "documento_como_modelo"}
								</h4>
							) : (
								<AutosizeInput
									inputClassName="text-[#3D88B4] p-0  m-0 h-[27px]  text-h4 font-semibold border-none bg-transparent  focus-visible:!outline-none max-w-[248px] overflow-hidden text-ellipsis whitespace-nowrap"
									value={nodeName || "documento_como_modelo"}
									onChange={(event) =>
										setNodeName(event.target.value)
									}
									onBlur={onSaveTitle}
									autoFocus
									ref={inputRef}
									onKeyDown={handleKeyDown}
									maxLength={50}
								/>
							)}
						</div>
					</div>

					<div className="flex items-center gap-2 fill-white">
						<Button
							variant={ButtonVariant.Contained}
							color={ButtonColor.SecondaryV2}
							className="text-small-1 !w-fit !h-[24px] !p-[4px] !bg-[#3D88B4]"
							icon={<PlayIcon size="16" />}
							iconPlacement={ButtonIconPlacement.End}
						/>
					</div>
				</div>

				<div className="p-4 bg-brand-white-2 rounded-lg">
					<div className="flex items-center justify-between w-full mb-3">
						<p className="text-cta-2">Input</p>
						<p className="text-cta-2 text-[#6D6D6D]">{`{tribunais}`}</p>
					</div>

					<textarea
						className="nodrag w-full p-3 rounded-lg border-[#F0F0F0] placeholder:text-[#B0B0B0] min-h-[104px]"
						placeholder="Digite seu comando"
					/>
				</div>
				<div className="p-4 bg-brand-white-2 rounded-lg">
					<div
						className={`flex items-center justify-between w-full  ${
							showOutput && "mb-3"
						}`}
					>
						<p className="text-cta-2">Output</p>
						<Center
							className="p-[4px] rounded border-[1px] border-[#F0F0F0] nodrag cursor-pointer"
							onClick={() => setShowOutput(!showOutput)}
						>
							<TbEye size="16px" color="#6D6D6D" />
						</Center>
					</div>
					{showOutput && (
						<>
							<textarea className="nodrag w-full p-3 rounded-lg border-[#F0F0F0] placeholder:text-[#B0B0B0] min-h-[104px]" />
						</>
					)}
				</div>
			</div>
		</ActionNodeHandler>
	)
}
