import { useQueryClient } from "@tanstack/react-query"

import FilterIcon from "icons/FilterIcon"

import SearchIcon from "icons/SearchIcon"
import { useEffect, useState } from "react"
import CloseIcon from "icons/CloseIcon"
import InputField from "components/input-field"
import { useJwt } from "hooks/useJwt"
import { SortingState } from "@tanstack/react-table"
import { UserTable } from "./subcomponents/UserTable"
import { useFeatureFlag } from "hooks/useFeatureFlag"
import FEATURE_FLAGS from "feature-flags"
import { Navigate } from "react-router-dom"

const InternalDashboard = () => {
	const [filterValue, setFilterValue] = useState("")
	const { data: isAdminUserFeatureFlagEnabled } = useFeatureFlag(
		FEATURE_FLAGS.isAdminUser,
	)
	const [focusSearchBar, setFocusSearchBar] = useState(false)
	const queryClient = useQueryClient()

	const [jwt] = useJwt()
	const [sorting, setSorting] = useState<SortingState>([])

	function handleClearSearchBar() {
		setFilterValue("")
	}

	const scrollToTop = () => {
		const element = document.getElementById("document-content")

		if (element) {
			element.scrollTo({
				top: 0,
			})
		}
	}

	useEffect(() => {
		scrollToTop()
		const interval = setInterval(() => {
			queryClient.invalidateQueries({
				queryKey: ["listLegalDocuments", jwt],
			})
		}, 10000)

		return () => clearInterval(interval)
	}, [])

	if (
		!isAdminUserFeatureFlagEnabled &&
		isAdminUserFeatureFlagEnabled !== undefined
	)
		return <Navigate to="/" />

	return (
		<div className="h-full" id="document">
			<div className="flex items-center justify-between">
				<h1 className="font-semibold">Usuários</h1>
			</div>
			<div className="flex-end flex mt-8 w-full">
				<div onBlur={() => setFocusSearchBar(false)} className="w-full">
					<InputField
						className="w-full"
						type="text"
						name="searchBar"
						placeholder="Buscar"
						value={filterValue}
						onChange={(e) => setFilterValue(e.target.value)}
						onFocus={() => {
							setFocusSearchBar(true)
						}}
						leftIcon={
							<SearchIcon
								className={
									focusSearchBar || filterValue
										? "fill-current"
										: "fill-[#ACACAC]"
								}
							/>
						}
						rightIcon={
							(focusSearchBar || !!filterValue) && (
								<div className="pt-1">
									{" "}
									<div
										className="cursor-pointer"
										onFocus={() => {
											setFocusSearchBar(true)
										}}
										onClick={handleClearSearchBar}
									>
										<CloseIcon />
									</div>
								</div>
							)
						}
					/>
				</div>

				<div className="ml-4 my-3 cursor-pointer">
					<FilterIcon />
				</div>
			</div>
			<UserTable
				data={[
					{
						user: {
							avatar: "https://lh3.googleusercontent.com/a/ACg8ocKdBn9kHfIkUxGBVBjn413ET0Tcdtbpu3aWL3nrJSsd=s96-c",
							name: "Douglas",
							email: "douglas@lawdeck.ai",
						},
						createdDocs: 211,
						credits: 2121,
						Ltv: 2910,
						lastLogin: { date: "23/02/2024", hour: "09:50:23" },
						type: "profissional",
					},
				]}
				filterValue={filterValue}
				columnFilterValue={[]}
				sorting={sorting}
				onFilterValueChange={setFilterValue}
				onSortingChange={setSorting}
			/>
		</div>
	)
}

export default InternalDashboard
