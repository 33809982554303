import { atom } from "jotai"
import { defaultRanges } from "../constants"
import { NodeTypes, Variables } from "../types"
import { GraphExecutionResponse } from "types/agentBuilder"

interface Ranges {
	title: string
	max: number
	value: number
	id: string
}

export const typeAtom = atom<NodeTypes | null>(null)
export const openConfigAtom = atom<string | null>("")
export const rangesAtom = atom<
	{
		nodeId: string
		ranges: Ranges[]
		llm: string
	}[]
>(defaultRanges)

export const variablesAtom = atom<Variables[]>([])

export const graphExecutionAtom = atom<GraphExecutionResponse | null>(null)
