import { AxiosResponse } from "axios"
import axiosInstance from "axiosInstance"
import { EdgeData, Graph, GraphCheckpoint, GraphData, GraphExecutionPayload, GraphExecutionResponse, GraphNode, GraphResponse, InputVariable, NodeExecutionPayload, NodeExecutionResponse, ParserData } from "types/agentBuilder"

const useAgentBuilder = () => {
  const listGraphs = async () => {
    const response: AxiosResponse<GraphResponse> =
      await axiosInstance.get("/agent-builder/graph/")

    return response.data
  }

  const getGraph = async (id: string) => {
    const response: AxiosResponse<GraphData> =
      await axiosInstance.get(`/agent-builder/graph/${id}/`)

    return response.data
  }

  const addGraph = async (data: Partial<Graph>) => {
    const response = await axiosInstance.post("/agent-builder/graph/", data)
    return response.data
  }


  const editGraph = async (data: Partial<Graph>) => {
    await axiosInstance.put(`/agent-builder/graph/${data.id}/`, data)
  }

  const deleteGraph = async (id: string) => {
    await axiosInstance.delete(`/agent-builder/graph/${id}/`)
  }

  const addNode = async (data: Partial<GraphNode>) => {
    const response: AxiosResponse<GraphNode> = await axiosInstance.post(`/agent-builder/node/`, data)
    return response.data
  }

  const deleteNode = async (id: string) => {
    await axiosInstance.delete(`/agent-builder/node/${id}/`)
  }


  const editNode = async (data: Partial<GraphNode>) => {
    await axiosInstance.put(`/agent-builder/node/${data.id}/`, data)
  }

  const listParsers = async () => {
    const response: AxiosResponse<ParserData[]> = await axiosInstance.get("/agent-builder/parser")
    return response.data
  }

  const addNewEdge = async (data: {
    from_node: string
    to_node: string
  }) => {
    const response: AxiosResponse<EdgeData> = await axiosInstance.post(`/agent-builder/edge/`, data)
    return response.data
  }

  const deleteEdge = async (id: string) => {
    await axiosInstance.delete(`/agent-builder/edge/${id}/`)
  }

  const nodeExecutionStart = async (data: NodeExecutionPayload) => {
    const response: AxiosResponse<NodeExecutionResponse> = await axiosInstance.post(`/agent-builder/node-execution/`, data)
    return response.data
  }

  const getNodeExecution = async (id: string) => {
    const response: AxiosResponse<NodeExecutionResponse> = await axiosInstance.get(`/agent-builder/node-execution/${id}/`)
    return response.data
  }

  const getAvailableLLMS = async () => {
    const response: AxiosResponse<string[]> = await axiosInstance.get(`/agent-builder/available-llms`)
    return response.data
  }

  const addInputVariable = async (data: InputVariable) => {
    const response: AxiosResponse<InputVariable> = await axiosInstance.post(`/agent-builder/input-variable/`, data)
    return response.data
  }

  const editInputVariable = async (data: InputVariable) => {
    await axiosInstance.put(`/agent-builder/input-variable/${data.id}/`, data)
  }

  const deleteInputVariable = async (id?: string) => {
    if (!id) return
    await axiosInstance.delete(`/agent-builder/input-variable/${id}/`)
  }

  const graphExecutionStart = async (data: GraphExecutionPayload) => {
    const response: AxiosResponse<GraphExecutionResponse> = await axiosInstance.post(`/agent-builder/graph-execution/`, data)
    return response.data
  }

  const getGraphExecution = async (id: string) => {
    const response: AxiosResponse<GraphExecutionResponse> = await axiosInstance.get(`/agent-builder/graph-execution/${id}/`)
    return response.data
  }


  const getGraphCheckpoints = async (graph: string) => {
    const response: AxiosResponse<GraphCheckpoint[]> = await axiosInstance.get(`/agent-builder/graph/${graph}/checkpoints/`)
    return response.data
  }

  const editCheckpoint = async ({ graphId, data, checkpointId }: {
    graphId: string
    data: Partial<GraphCheckpoint>
    checkpointId: string
  }) => {
    await axiosInstance.put(`/agent-builder/graph/${graphId}/checkpoints/${checkpointId}/`, data)
  }

  const addGraphCheckpoint = async (graph: string) => {
    await axiosInstance.post(`/agent-builder/graph/${graph}/checkpoints/`, {})
  }

  const checkpointRestore = async (checkpoint: string) => {
    await axiosInstance.post(`/agent-builder/graph-checkpoint/${checkpoint}/restore/`, {})
  }

  const publishCheckpoint = async ({
    checkpoint,

  }: {
    checkpoint: string
  }) => {
    const response: AxiosResponse<GraphCheckpoint> = await axiosInstance.post(`/agent-builder/graph-checkpoint/${checkpoint}/publication/`, {})
    return response.data
  }

  const publishGraph = async ({
    graphId,

  }: {
    graphId: string
  }) => {
    const response: AxiosResponse<GraphCheckpoint> = await axiosInstance.post(`/agent-builder/graph/${graphId}/publish/`, {})
    return response.data
  }


  const unPublishCheckpoint = async (checkpoint: string) => {
    await axiosInstance.post(`/agent-builder/graph-checkpoint/${checkpoint}/unpublish`, {})
  }


  return {
    listGraphs,
    addGraph,
    editGraph,
    deleteGraph,
    getGraph,
    addNode,
    listParsers,
    editNode,
    addNewEdge,
    deleteEdge,
    nodeExecutionStart,
    getNodeExecution,
    deleteNode,
    getAvailableLLMS,
    addInputVariable,
    editInputVariable,
    deleteInputVariable,
    graphExecutionStart,
    getGraphExecution,
    getGraphCheckpoints,
    addGraphCheckpoint,
    checkpointRestore,
    publishCheckpoint,
    unPublishCheckpoint,
    publishGraph,
    editCheckpoint
  }
}
export default useAgentBuilder
