import BaseIcon from "./BaseIcon"

const HistoryIcon = () => {
	return (
		<BaseIcon
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14.3863 4.90216C13.1521 3.82515 11.5647 3.24019 9.92675 3.2589C8.28883 3.27761 6.71514 3.89867 5.5059 5.00359C4.29666 6.10852 3.53653 7.61996 3.37054 9.24955C3.3239 9.70741 2.91492 10.0408 2.45705 9.99414C1.99918 9.9475 1.66581 9.53852 1.71245 9.08065C1.91995 7.04366 2.87011 5.15436 4.38165 3.77321C5.8932 2.39205 7.86032 1.61573 9.90771 1.59234C11.9551 1.56896 13.9394 2.30015 15.4821 3.64642C17.0248 4.99269 18.0179 6.85979 18.2719 8.89151C18.5258 10.9232 18.0229 12.9773 16.8591 14.6619C15.6952 16.3465 13.9519 17.5436 11.9618 18.025C9.97164 18.5063 7.87395 18.238 6.06893 17.2715C5.00835 16.7035 4.09243 15.9178 3.37483 14.9762V16.6651C3.37483 17.1253 3.00173 17.4984 2.5415 17.4984C2.08126 17.4984 1.70816 17.1253 1.70816 16.6651V12.4984C1.70816 12.0382 2.08126 11.6651 2.5415 11.6651H2.93872C2.95167 11.6648 2.96458 11.6648 2.97746 11.6651H6.70816C7.1684 11.6651 7.5415 12.0382 7.5415 12.4984C7.5415 12.9587 7.1684 13.3318 6.70816 13.3318H4.27231C4.88857 14.3684 5.77895 15.2256 6.85572 15.8022C8.29973 16.5755 9.97789 16.79 11.57 16.405C13.1621 16.0199 14.5568 15.0622 15.4878 13.7146C16.4189 12.3669 16.8212 10.7236 16.6181 9.09823C16.4149 7.47286 15.6205 5.97918 14.3863 4.90216ZM9.99999 5.83333C10.4602 5.83333 10.8333 6.20643 10.8333 6.66667V9.65482L12.2559 11.0774C12.5814 11.4028 12.5814 11.9305 12.2559 12.2559C11.9305 12.5814 11.4028 12.5814 11.0774 12.2559L9.41074 10.5893C9.25446 10.433 9.16666 10.221 9.16666 10V6.66667C9.16666 6.20643 9.53976 5.83333 9.99999 5.83333Z"
				fill="currentColor"
			/>
		</BaseIcon>
	)
}

export default HistoryIcon
