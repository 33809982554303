import { Button } from "components/button"
import { ButtonVariant } from "components/button/types"
import Prompts from "./subcomponents/Prompts"
import useAgentBuilder from "hooks/useAgentBuilder"
import { useMutation, useQuery } from "@tanstack/react-query"
import { Navigate, useNavigate } from "react-router-dom"
import PlusIcon from "icons/PlusIcon"
import { nodesDefaultSizes, serializeNode } from "pages/GraphPage/utils"
import { useFeatureFlag } from "hooks/useFeatureFlag"
import FEATURE_FLAGS from "feature-flags"
import { useAtom } from "jotai"
import { graphExecutionAtom } from "pages/GraphPage/atoms"

const columnHeader = [
	{
		title: "Nome",
		key: "name",
		width: "w-[30%]",
	},
	{
		title: "Status",
		key: "status",
		width: "w-[15%]",
	},
	{
		title: "Última modificação",
		key: "lastModified",
		width: "w-[17%]",
	},
	{
		title: "Última publicação",
		key: "lastPublished",
		width: "w-[17%]",
	},
	{
		title: "Performance",
		key: "performance",
		width: "w-[16%]",
	},
	{
		title: "",
		key: "actions",
		width: "w-[5%]",
	},
]

export default function AgentBuilderPage() {
	const { data: isAdminUserFeatureFlagEnabled } = useFeatureFlag(
		FEATURE_FLAGS.isAdminUser,
	)
	const { listGraphs, addGraph, addNode } = useAgentBuilder()
	const [, setGraphExecution] = useAtom(graphExecutionAtom)

	const { data } = useQuery(["listGraphs"], listGraphs)
	const navigate = useNavigate()
	const addNodeMutation = useMutation({
		mutationFn: addNode,
	})
	const addGraphMutation = useMutation({
		mutationFn: addGraph,
		onSuccess: async (data) => {
			setGraphExecution(null)
			const nodeToSend = serializeNode(
				{
					id: "1",
					type: "variables",
					data: {
						nodeData: {
							name: "Variáveis_1",
						},
						graphId: "",
					},
					position: { x: 250, y: 5 },
					size: {
						...nodesDefaultSizes[
							"variables" as keyof typeof nodesDefaultSizes
						],
					},
				},
				data.id,
			)
			addNodeMutation.mutate(nodeToSend)

			navigate(`/graph/${data.id}`)
		},
	})

	function onAddGraph() {
		addGraphMutation.mutate({
			name: "Novo Agente",
		})
	}

	if (
		!isAdminUserFeatureFlagEnabled &&
		isAdminUserFeatureFlagEnabled !== undefined
	)
		return <Navigate to="/" />

	if (!data) return

	return (
		<div className="flex flex-col gap-8 w-full">
			<div className="flex items-center justify-between ">
				<h3>Agentes</h3>
				<div className="flex items-center gap-4">
					<Button
						variant={ButtonVariant.Contained}
						icon={<PlusIcon />}
						onClick={onAddGraph}
					>
						Novo agente
					</Button>
				</div>
			</div>

			<div className="w-full flex items-center px-4 ">
				{columnHeader.map((column) => (
					<div key={column.key} className={`${column.width}`}>
						<p className="font-semibold text-dark-blue-1">
							{column.title}
						</p>
					</div>
				))}
			</div>
			<div className="flex flex-col gap-6">
				{data.results.map((graph) => (
					<Prompts
						columnHeader={columnHeader}
						key={graph.id}
						graph={graph}
					/>
				))}
			</div>
		</div>
	)
}
