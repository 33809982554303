import { useMutation } from "@tanstack/react-query"
import useAgentBuilder from "hooks/useAgentBuilder"
import { useRef, useEffect } from "react"
import { serializeNode } from "../utils"
import { Node } from "../subcomponents/Flow"
import { useAtom } from "jotai"
import { rangesAtom } from "../atoms"

interface SaveNodeParams {
	id?: string
	graphId: string
	currentNode: Node | undefined
	additionalParamToSave?: any
}

export const useSaveNode = ({
	id,
	graphId,
	currentNode,
	additionalParamToSave,
}: SaveNodeParams) => {
	const saveTimeout = useRef<NodeJS.Timeout | null>(null)
	const { editNode } = useAgentBuilder()
	const [ranges] = useAtom(rangesAtom)

	const editNodeMutation = useMutation({
		mutationFn: editNode,
	})
	async function saveNode() {
		if (!currentNode) return

		const currentRange =
			ranges && currentNode.type === "prompt"
				? ranges.find((r) => r.nodeId === id)
				: undefined

		const serializedNode = serializeNode(currentNode, graphId, currentRange)
		await editNodeMutation.mutateAsync(serializedNode)
	}

	useEffect(() => {
		if (saveTimeout.current) {
			clearTimeout(saveTimeout.current)
		}

		saveTimeout.current = setTimeout(() => {
			saveNode()
		}, 1000)

		return () => {
			if (saveTimeout.current) {
				clearTimeout(saveTimeout.current)
			}
		}
	}, [currentNode, additionalParamToSave])
}
