import React from "react"

interface RangeSliderProps {
	min?: number
	max: number
	value: number
	onChange: (value: number) => void
	title?: string
	step?: number
}

const RangeSlider = ({
	min = 0,
	max = 200,
	onChange,
	title,
	value,
	step = 1,
}: RangeSliderProps) => {
	const handleRangeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = parseFloat(event.target.value)
		onChange(newValue)
	}

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = parseFloat(event.target.value)
		if (!isNaN(newValue) && newValue >= min && newValue <= max) {
			onChange(newValue)
		} else {
			onChange(0)
		}
	}

	const handleBlur = () => {
		if (!value) {
			onChange(min)
		}
	}
	const inputWidth = `${String(value).length + 1}ch`

	return (
		<div>
			<div className="flex w-full justify-between items-center">
				{title && <p className="text-brand-gray-3 mb-2">{title}</p>}
				<input
					type="number"
					value={value}
					onChange={handleInputChange}
					min={min}
					max={max}
					step={step}
					onBlur={handleBlur}
					className=" border rounded text-center no-arrows p-0 border-none"
					style={{
						width: inputWidth,
					}}
				/>
			</div>
			<div className="flex items-center space-x-2">
				<input
					type="range"
					min={min}
					max={max}
					step={step}
					value={value}
					onChange={handleRangeChange}
					className="range-slider w-full h-2 rounded-lg appearance-none cursor-pointer"
					style={{
						background: `linear-gradient(to right, #3083FF ${
							(value / max) * 100
						}%, #e5e7eb ${(value / max) * 100}%)`,
					}}
				/>
			</div>
		</div>
	)
}

export default RangeSlider
