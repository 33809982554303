import { useMutation } from "@tanstack/react-query"
import { Dropdown } from "components/dropdown"
import TitleTooltip from "components/title-tooltip"
import { format } from "date-fns"
import useAgentBuilder from "hooks/useAgentBuilder"
import { RefObject, useEffect, useRef, useState } from "react"
import { TbCalendarCheck, TbDots, TbPencil, TbTrash } from "react-icons/tb"
import AutosizeInput from "react-input-autosize"
import { useParams } from "react-router-dom"
import { GraphCheckpoint, GraphData } from "types/agentBuilder"

export default function Step({
	checkpoint,
	onRestoreCheckPoint,
	handlePublishCheckpoint,
	checkpoints,
	index,
	graph,
}: {
	checkpoint: GraphCheckpoint
	onRestoreCheckPoint: (checkpointId: string) => void
	handlePublishCheckpoint: (checkpoint: string) => void
	index: number
	checkpoints: GraphCheckpoint[]
	graph: GraphData
}) {
	const [hover, setHover] = useState(false)
	const [isEdit, setIsEdit] = useState(false)
	const [inputTitle, setInputTitle] = useState("")
	const inputRef: RefObject<AutosizeInput> &
		(string | RefObject<HTMLInputElement>) = useRef(null)
	const isActive = checkChekpointIsPublished()
	const { graphId = "" } = useParams()

	const { editCheckpoint } = useAgentBuilder()

	const editCheckpointMutation = useMutation({
		mutationFn: editCheckpoint,
	})

	const sections = [
		{
			items: [
				{
					label: "Renomear",
					onClick: () => {
						setIsEdit(true)
					},
					icon: <TbPencil size="20px" />,
				},
				{
					label: "Publicar",
					onClick: () => {
						handlePublishCheckpoint(checkpoint.id)
					},
					icon: <TbCalendarCheck size="20px" />,
				},
				{
					label: "Excluir",
					deleteLabel: true,
					onClick: () => {
						// setOpen(true)
						// setDocumentId(document.id)
						// setTitle(document.title)
					},
					icon: <TbTrash size="20px" />,
				},
			],
		},
	]

	const onSaveTitle = async () => {
		editCheckpointMutation.mutateAsync({
			graphId: graphId,
			data: {
				name: inputTitle,
			},
			checkpointId: checkpoint.id,
		})
		setIsEdit(false)
	}

	const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === "Enter") {
			event.preventDefault()
			onSaveTitle()
		}
	}

	function checkChekpointIsPublished() {
		if (!checkpoint?.published_at || !graph?.last_publication) {
			return false
		}

		const lastCheckpointPublication = new Date(checkpoint.published_at)
		const lastGraphPublication = new Date(graph.last_publication)

		return (
			lastCheckpointPublication.getTime() ===
			lastGraphPublication.getTime()
		)
	}

	useEffect(() => {
		if (isEdit && inputRef.current) {
			inputRef.current.select()
		}
	}, [isEdit])

	return (
		<div
			className="group flex items-center pl-[47px]  h-[76px] hover:bg-[#F2F6FB] cursor-pointer"
			onClick={() => onRestoreCheckPoint(checkpoint.id)}
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
		>
			<span
				className={`absolute flex items-center justify-center w-6 h-6  border-[1px] rounded-full left-[39px] ring-5 ring-white ${
					isActive
						? "fill-brand-green bg-green-opacity border-brand-green text-brand-green"
						: "fill-brand-gray-1 bg-white border-brand-gray-1 text-[#6D6D6D]"
				}`}
			>
				{checkpoint?.published_at && <TbCalendarCheck />}
			</span>
			<div
				className="hidden group-hover:block ml-auto text-brand-gray-2 cursor-pointer absolute right-[16px]"
				onClick={(e) => {
					e.stopPropagation()
				}}
			>
				{hover && (
					<Dropdown sections={sections} className="right-0 w-[165px]">
						<TbDots color="#6D6D6D" size="16px" />
					</Dropdown>
				)}
			</div>

			{checkpoints.length - 1 !== index && (
				<div className="relative">
					<div
						className={`absolute w-[1px] h-[100px] top-[11px] left-[-21.5px] bg-brand-gray-1 ${
							index === checkpoints.length - 2 && "!h-[60px]"
						}`}
					/>
				</div>
			)}
			<div>
				{!isEdit ? (
					<div className="flex items-center ">
						<TitleTooltip text={inputTitle || ""}>
							<p
								className={`text-cta-2 font-semibold ${
									isActive
										? "text-brand-green"
										: "text-brand-gray-3"
								}`}
							>
								{checkpoint?.name ||
									format(
										new Date(checkpoint._created_at),
										"dd/MM/yyyy - HH:mm",
									)}
							</p>
						</TitleTooltip>
					</div>
				) : (
					<AutosizeInput
						inputClassName=" text-[#6D6D6D] p-0 m-0 text-cta-2 font-semibold border-none bg-transparent  focus-visible:!outline-none max-w-[calc(100vw-600px)] overflow-hidden text-ellipsis whitespace-nowrap"
						value={inputTitle || ""}
						onChange={(event) => setInputTitle(event.target.value)}
						onBlur={onSaveTitle}
						autoFocus
						ref={inputRef}
						onKeyDown={handleKeyDown}
					/>
				)}
				<p className={`text-small-1  text-brand-gray-2`}>
					{checkpoint.created_by.first_name}{" "}
					{checkpoint.created_by.last_name}
				</p>
			</div>
		</div>
	)
}
