import CloseIcon from "icons/CloseIcon"
import RangeSlider from "components/range-slider"
import { useAtom } from "jotai"
import { rangesAtom } from "../atoms"
import { useQuery } from "@tanstack/react-query"
import useAgentBuilder from "hooks/useAgentBuilder"
import { Button } from "components/button"
import { ButtonVariant } from "components/button/types"
import Center from "components/center"
import { defaultRanges } from "../constants"
import { useSaveNode } from "../hooks/useSaveNode"
import { useReactFlow } from "@xyflow/react"
import { Node } from "./Flow"
import { useParams } from "react-router-dom"

export default function PromptConfig({
	setOpenConfig,
	openConfig,
}: {
	setOpenConfig: (value: string | null) => void
	openConfig: string
}) {
	const [ranges, setRanges] = useAtom(rangesAtom)
	const { getAvailableLLMS } = useAgentBuilder()
	const currentRange = ranges.find((r) => r.nodeId === openConfig)
	const { data: llms } = useQuery(["llms"], getAvailableLLMS)
	const { getNode } = useReactFlow()
	const { graphId = "" } = useParams()

	const currentNode = getNode(openConfig) as Node | undefined

	if (!currentRange) return null

	function onResetDefault() {
		const newRanges = ranges.map((r) => {
			if (r.nodeId === openConfig) {
				return {
					...r,
					ranges: defaultRanges[0].ranges,
				}
			}
			return r
		})
		setRanges(newRanges)
	}

	useSaveNode({
		currentNode,
		graphId,
		id: openConfig,
		additionalParamToSave: ranges,
	})

	return (
		<div className="bg-white w-[330px] h-[calc(100vh-52px)] fixed z-50 right-0 top-[52px] border-l-[1px] border-[#F0F0F0]">
			<div className="h-full flex flex-col justify-between">
				<div className="h-full">
					<div className="p-6 flex flex-col gap-6">
						<div className="flex items-center justify-between text-dark-blue-1">
							<p className="text-cta-1">Configurações</p>
							<div
								onClick={() => setOpenConfig(null)}
								className="cursor-pointer"
							>
								<CloseIcon size="20" />
							</div>
						</div>
						<select
							value={currentRange.llm}
							onChange={(e) => {
								setRanges((prev) =>
									prev.map((r) =>
										r.nodeId === openConfig
											? {
													...r,
													llm: e.target.value,
											  }
											: r,
									),
								)
							}}
							id="parser"
							className="text-gray-900 text-sm rounded-md focus:ring-blue-500  block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 bg-white dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 border-[1px] border-brand-gray-1 py-3 placeholder:text-[#ACACAC] focus:border-light-blue-4"
						>
							{llms &&
								llms.map((parser) => (
									<option value={parser} key={parser}>
										{parser}
									</option>
								))}
						</select>

						<div className="w-full h-[1px] bg-[#F0F0F0] " />
					</div>
					<div className="p-6 pt-0 flex flex-col gap-6">
						{currentRange.ranges.map((range, index) => (
							<RangeSlider
								title={range.title}
								max={range.max}
								step={range.id === "max_tokens" ? 1 : 0.05}
								onChange={(value: number) => {
									setRanges((prev) =>
										prev.map((r) =>
											r.nodeId === openConfig
												? {
														...r,
														ranges: r.ranges.map(
															(rr) =>
																rr.id ===
																range.id
																	? {
																			...rr,
																			value,
																	  }
																	: rr,
														),
												  }
												: r,
										),
									)
								}}
								value={range.value}
								key={index}
							/>
						))}
					</div>
				</div>

				<Center className="px-6 pb-6">
					<Button
						variant={ButtonVariant.Outlined}
						className="w-full items-center justify-center"
						onClick={onResetDefault}
					>
						Reset default
					</Button>
				</Center>
			</div>
		</div>
	)
}
