import { Position, useReactFlow } from "@xyflow/react"
import { Button } from "components/button"
import { ButtonVariant } from "components/button/types"
import Center from "components/center"
import InputField from "components/input-field"
import PlusIcon from "icons/PlusIcon"
import { BsBraces } from "react-icons/bs"
import Handle from "../subcomponents/Handle"
import { useEffect, useRef } from "react"
import VariableDropDown from "../subcomponents/VariableDropDown"
import { HiOutlineTrash } from "react-icons/hi"
import IconBox from "../subcomponents/IconBox"
import VariableValues from "../subcomponents/VariableValues"
import { TbPaperclip } from "react-icons/tb"
import CloseIcon from "icons/CloseIcon"
import TagFile1 from "icons/TagFile1"
import TagFile2 from "icons/TagFile2"
import useAgentBuilder from "hooks/useAgentBuilder"
import { useMutation, useQuery } from "@tanstack/react-query"
import { Node, NodeData } from "../subcomponents/Flow"
import { useAtom } from "jotai"
import { variablesAtom } from "../atoms"
import { serializeNode } from "../utils"

const inputVariables = [
	{
		label: "Texto",
		value: "TEXT",
	},
	{
		label: "Número",
		value: "NUMBER",
	},

	{
		label: "Anexo",
		value: "FILE",
	},
]

export default function Variables({
	data,
	id,
}: {
	data: NodeData
	id: string
}) {
	const [variables, setVariables] = useAtom(variablesAtom)
	const {
		addInputVariable,
		editInputVariable,
		getGraph,
		deleteInputVariable,
		editNode,
	} = useAgentBuilder()
	const { getNode } = useReactFlow()
	const saveTimeout = useRef<NodeJS.Timeout | null>(null)
	const currentNode = getNode(id) as Node | undefined

	const { data: graph } = useQuery(
		["graph", data.graphId],
		() => getGraph(data.graphId),

		{
			refetchOnWindowFocus: false,
		},
	)

	const editNodeMutation = useMutation({
		mutationFn: editNode,
	})

	const addInputVariableMutation = useMutation({
		mutationFn: addInputVariable,
	})

	const editInputVariableMutation = useMutation({
		mutationFn: editInputVariable,
	})

	const deleteInputVariableMutation = useMutation({
		mutationFn: deleteInputVariable,
	})

	function onChangeInputVariable(value: string, index: number) {
		const newVariables = [...variables]
		newVariables[index].type = value
		newVariables[index].value = ""
		setVariables(newVariables)
		onSaveVariable(index)
	}

	function variableToShow(selectedVariable: string) {
		const variable = inputVariables.find(
			(variable) => variable.value === selectedVariable,
		)

		if (selectedVariable === "NUMBER") {
			return "Núm."
		}
		return variable?.label || ""
	}

	function onDeleteVariable(index: number) {
		const newVariables = [...variables]
		newVariables.splice(index, 1)
		setVariables(newVariables)
		if (variables[index]?.id) {
			deleteInputVariableMutation.mutateAsync(variables[index].id)
		}
	}

	async function onAddVariable() {
		setVariables((prev) => [
			...prev,
			{
				name: "",
				value: "",
				type: "TEXT",
			},
		])
	}

	async function onSaveVariable(index: number) {
		const currentVariable = variables[index]
		if (!currentVariable.name) {
			return
		}
		if (currentVariable?.id) {
			await editInputVariableMutation.mutateAsync({
				name: currentVariable.name.slice(1, -1),
				type: currentVariable.type,
				graph: data.graphId,
				id: currentVariable.id,
			})
		} else {
			const response = await addInputVariableMutation.mutateAsync({
				name: currentVariable.name.slice(1, -1),
				type: currentVariable.type,
				graph: data.graphId,
			})
			setVariables((prev) => {
				const newVariables = [...prev]
				newVariables[index].id = response.id
				return newVariables
			})
		}
	}

	async function saveNode() {
		if (!currentNode) return
		const serializedNode = serializeNode(currentNode, data.graphId)
		await editNodeMutation.mutateAsync(serializedNode)
	}

	useEffect(() => {
		if (graph) {
			if (graph.input_variables.length === 0) {
				setVariables([
					{
						name: "",
						value: "",
						type: "TEXT",
					},
				])
			} else {
				const newVariables = graph.input_variables.map((variable) => {
					return {
						name: `{${variable.name}}`,
						value: "",
						type: variable.type,
						id: variable.id,
					}
				})
				setVariables(newVariables)
			}
		}
	}, [graph])

	useEffect(() => {
		if (saveTimeout.current) {
			clearTimeout(saveTimeout.current)
		}
		saveTimeout.current = setTimeout(() => {
			saveNode()
		}, 1000)

		return () => {
			if (saveTimeout.current) {
				clearTimeout(saveTimeout.current)
			}
		}
	}, [currentNode])

	return (
		<div className="w-[400px] p-2 flex flex-col gap-2 bg-white border-[1px] border-brand-white-4 rounded-2xl">
			<Handle
				type="source"
				position={Position.Right}
				style={{
					width: "16px",
					height: "16px",
					backgroundColor: "white",
					border: "1px solid #E7E7E7",
				}}
			/>
			<div className="p-2 bg-[#FFF2E0] flex  items-center text-[#D37C3D] font-semibold rounded-lg gap-3">
				<Center className="w-[48px] h-[48px] p-2 bg-white rounded">
					<BsBraces size="32px" color="#D37C3D" />
				</Center>

				<div className="flex flex-col ">
					<p className="text-small-1">Input de variáveis</p>
					<h4 className=" text-[#D37C3D]">variavéis_1</h4>
				</div>
			</div>

			<div className="p-4 bg-brand-white-2 rounded-lg">
				{variables.map((variable, index) => {
					return (
						<div key={index}>
							<div className=" flex gap-2 text-cta-2">
								<div>
									{index === 0 && (
										<p className="font-semibold">
											Nome da variável
										</p>
									)}
									<div className="mt-3 relative nodrag">
										<InputField
											placeholder="Nome"
											className="w-[156px] h-[44px] pr-[55px] truncate"
											value={variable.name}
											onChange={(e) => {
												const newVariables = [
													...variables,
												]
												newVariables[index].name =
													e.target.value
												setVariables(newVariables)
											}}
											onBlur={() => {
												const newVariables = [
													...variables,
												]
												let name =
													newVariables[
														index
													].name.trim()
												if (name) {
													name = `{${name}}`
												}
												newVariables[index].name = name
												setVariables(newVariables)
												onSaveVariable(index)
											}}
											onFocus={() => {
												const newVariables = [
													...variables,
												]
												let name =
													newVariables[
														index
													].name.trim()

												if (
													name.startsWith("{") &&
													name.endsWith("}")
												) {
													name = name.slice(1, -1)
												}
												newVariables[index].name = name
												setVariables(newVariables)
											}}
										/>
										<div className="absolute top-[10px] right-[12px]  cursor-pointer">
											<VariableDropDown
												items={inputVariables}
												variableIndex={index}
												onChange={onChangeInputVariable}
											>
												<Center className="px-1 py-[2px] bg-[#F0F0F0] rounded-sm w-[42px]">
													<p className="text-small-1 ">
														{variableToShow(
															variable.type,
														)}
													</p>
												</Center>
											</VariableDropDown>
										</div>
									</div>
								</div>
								<div className="relative">
									{index === 0 && (
										<p className="font-semibold">Valor</p>
									)}
									<div className="w-[156px]">
										<VariableValues
											setVariables={setVariables}
											variables={variables}
											currentVariable={variable}
											currentIndex={index}
										/>
									</div>
									{index !== 0 && (
										<IconBox
											className="absolute top-[21px] right-[-36px] nodrag"
											onClick={() =>
												onDeleteVariable(index)
											}
										>
											<HiOutlineTrash
												size="16px"
												color="#6D6D6D"
											/>
										</IconBox>
									)}
								</div>
							</div>
							<div className="nodrag">
								{variable.type === "FILE" && variable.value && (
									<>
										{variable.value.map(
											(file: File, i: number) => {
												return (
													<div
														className="flex items-center"
														key={i}
													>
														{i ===
														variable.value.length -
															1 ? (
															<TagFile2 />
														) : (
															<TagFile1 />
														)}

														<div className="w-[288px] flex items-center justify-between p-2 bg-[#F5F5F5] border-[1px] border-[#F0F0F0] rounded-lg text-[#6D6D6D] text-small-1">
															<div className="flex items-center gap-1">
																<TbPaperclip size="16px" />
																<p className="w-[208px] truncate">
																	{file.name}
																</p>
															</div>
															<div
																className="cursor-pointer"
																onClick={() => {
																	const newVariables =
																		[
																			...variables,
																		]

																	newVariables[
																		index
																	].value =
																		variable.value.filter(
																			(
																				_: File,
																				fileIndex: number,
																			) =>
																				fileIndex !==
																				i,
																		)
																	setVariables(
																		newVariables,
																	)
																}}
															>
																<CloseIcon
																	size="16"
																	className="fill-[#6D6D6D]"
																/>
															</div>
														</div>
													</div>
												)
											},
										)}
									</>
								)}
							</div>
						</div>
					)
				})}

				<Button
					variant={ButtonVariant.Text}
					icon={<PlusIcon />}
					className="mt-3 h-fit"
					onClick={onAddVariable}
				>
					Adicionar variável
				</Button>
			</div>
		</div>
	)
}
