import { WS_SERVER } from "config"
import useAgentBuilder from "hooks/useAgentBuilder"
import { useJwt } from "hooks/useJwt"
import { useState, useEffect, useRef } from "react"
import { NodeExecutionResponse } from "types/agentBuilder"

const useNodeExecutionStatus = (nodeId: string) => {
	const wsRef = useRef<WebSocket>()
	const [jwt] = useJwt()
	const [nodeExecutionStatus, setNodeExecutionStatus] =
		useState<NodeExecutionResponse | null>(null)
	const { getNodeExecution } = useAgentBuilder()

	async function getNodeExecutionData(id: string) {
		const data = await getNodeExecution(id)
		setNodeExecutionStatus(data)
	}

	useEffect(() => {
		wsRef.current = new WebSocket(`${WS_SERVER.baseUrl}/?token=${jwt}`)
		wsRef.current.onopen = () => {}

		wsRef.current.onmessage = (e) => {
			const data = JSON.parse(e.data)
			console.log(
				data.payload.node_id === "node_wfZnSGqAzBitwJkkIsWs" && data,
			)
			if (
				data.event_type === "NODE_EXECUTION_STATUS_UPDATE" &&
				data.payload.node_id === nodeId
			) {
				if (data.payload.reason === "START") {
					setNodeExecutionStatus({
						...(nodeExecutionStatus as NodeExecutionResponse),
						status: "START",
					})
				} else {
					getNodeExecutionData(data.payload.node_execution_id)
				}
			}

			if (e.data === "ping") {
				wsRef.current?.send("pong")
				return
			}
		}

		wsRef.current.onerror = () => {
			console.log("ws error")
		}

		wsRef.current.onclose = () => {
			console.log("ws close")
		}

		return () => {
			wsRef.current?.close(1000, "finished")
		}
	}, [])

	return { nodeExecutionStatus, setNodeExecutionStatus }
}

export default useNodeExecutionStatus
