const TagFile1 = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="33"
			height="42"
			viewBox="0 0 33 42"
			fill="none"
		>
			<path
				d="M11.5 41C11.5 41.2761 11.7239 41.5 12 41.5C12.2761 41.5 12.5 41.2761 12.5 41L11.5 41ZM11.5 5L11.5 23L12.5 23L12.5 5L11.5 5ZM11.5 23L11.5 41L12.5 41L12.5 23L11.5 23Z"
				fill="#F0F0F0"
			/>
			<path
				d="M12 0.999999L12 16C12 18.7614 14.2386 21 17 21L32 21"
				stroke="#F0F0F0"
				strokeLinecap="round"
			/>
		</svg>
	)
}

export default TagFile1
