export const defaultRanges = [
	{
		nodeId: "",
		llm: "gpt-4o-mini-2024-07-18",
		ranges: [
			{
				title: "Temperatura",
				max: 2,
				value: 1,
				id: "temperature",
			},
			{
				title: "Comprimento máximo",
				max: 16384,
				value: 2048,
				id: "max_tokens",
			},
			{
				title: "Top P",
				max: 1,
				value: 1,
				id: "top_p",
			},
			{
				title: "Penalidade por Repetição",
				max: 2,
				value: 0,
				id: "frequency_penalty",
			},
			{
				title: "Penalidade por Presença",
				max: 2,
				value: 0,
				id: "presence_penalty",
			},
		],
	},
]
