import { calculateExecutionTime } from "./calculateExecutionTime "
import { formatExecutionTime } from "./formatExecutionTime"

export function getExecutionTime(start_time: string | undefined, end_time: string | undefined) {
  if (start_time && end_time) {
    const calculatedTime = calculateExecutionTime(
      start_time,
      end_time,
    ) || 0
    return `${formatExecutionTime(calculatedTime)}`
  } else {
    return "-"
  }
}