import { withProps } from "@udecode/cn"
import {
	createPlugins,
	Plate,
	PlateLeaf,
	createPlateEditor,
	PlateEditor,
	TElement,
} from "@udecode/plate-common"
import {
	createParagraphPlugin,
	ELEMENT_PARAGRAPH,
} from "@udecode/plate-paragraph"
import {
	createHeadingPlugin,
	ELEMENT_H1,
	ELEMENT_H2,
} from "@udecode/plate-heading"
import {
	createBlockquotePlugin,
	ELEMENT_BLOCKQUOTE,
} from "@udecode/plate-block-quote"
import { createLinkPlugin, ELEMENT_LINK } from "@udecode/plate-link"
import {
	createBoldPlugin,
	MARK_BOLD,
	createItalicPlugin,
	MARK_ITALIC,
} from "@udecode/plate-basic-marks"
import { createFontSizePlugin } from "@udecode/plate-font"
import { createAutoformatPlugin } from "@udecode/plate-autoformat"
import { createDeserializeDocxPlugin } from "@udecode/plate-serializer-docx"
import { createDeserializeCsvPlugin } from "@udecode/plate-serializer-csv"
import {
	createDeserializeMdPlugin,
	deserializeMd,
	serializeMd,
} from "@udecode/plate-serializer-md"
import { BlockquoteElement } from "components/document-text-area/blockquote-element"
import { LinkElement } from "components/document-text-area/link-element"
import { HeadingElement } from "components/document-text-area/heading-element"
import { ParagraphElement } from "components/document-text-area/paragraph-element"
import { TooltipProvider } from "components/document-text-area/tooltip"
import { FloatingToolbar } from "./elements/floating-toolbar"
import { FloatingToolbarButtons } from "./elements/floating-toolbar-buttons"
import { Editor } from "./elements/editor"
import { autoformatBlocks, autoformatMarks } from "utils/documentTextAreaConst"
import {
	ELEMENT_LI,
	ELEMENT_OL,
	ELEMENT_UL,
	createListPlugin,
} from "@udecode/plate-list"
import { createIndentPlugin } from "@udecode/plate-indent"
import { createIndentListPlugin } from "@udecode/plate-indent-list"
import { ListElement } from "./elements/list-element"
import { DetailedHTMLProps, useEffect, useRef, useState } from "react"
import _ from "lodash"
import { useLegalDocumentContentMutation } from "hooks/useLegalDocumentContentMutation"
import { LegalDocumentType } from "types/legalDocument"
import { Transforms, Node, Editor as EditorType, Element } from "slate"
import {
	isBlockAboveEmpty,
	isSelectionAtBlockStart,
} from "@udecode/plate-common"
import { createResetNodePlugin } from "@udecode/plate-reset-node"
import { ListItemElement } from "./listitem-element"

const autoformatRules = [...autoformatBlocks, ...autoformatMarks]
const resetBlockTypesCommonRule = {
	types: [ELEMENT_BLOCKQUOTE, ELEMENT_H1, ELEMENT_H2],
	defaultType: ELEMENT_PARAGRAPH,
}

const withEnterKeyHandler = (editor: any) => {
	const { insertBreak } = editor

	editor.insertBreak = () => {
		const { selection } = editor

		if (selection) {
			const [blockquoteNode] = EditorType.nodes(editor, {
				match: (n) =>
					!EditorType.isEditor(n) &&
					Element.isElement(n) &&
					"type" in n &&
					(n.type === ELEMENT_BLOCKQUOTE ||
						n.type === ELEMENT_H1 ||
						n.type === ELEMENT_H2),
			})

			if (blockquoteNode) {
				const node = {
					children: [{ text: "" }],
					type: ELEMENT_PARAGRAPH,
				} as Node
				Transforms.insertNodes(editor, node)
				return
			}
		}
		insertBreak()
	}
	return editor
}

const plugins = createPlugins(
	[
		createParagraphPlugin(),
		createHeadingPlugin(),
		createBlockquotePlugin(),
		createLinkPlugin(),
		createBoldPlugin(),
		createItalicPlugin(),
		createListPlugin(),
		createIndentPlugin({
			inject: {
				props: {
					validTypes: [
						ELEMENT_PARAGRAPH,
						ELEMENT_H1,
						ELEMENT_H2,
						ELEMENT_BLOCKQUOTE,
					],
				},
			},
		}),
		createIndentListPlugin({
			inject: {
				props: {
					validTypes: [
						ELEMENT_PARAGRAPH,
						ELEMENT_H1,
						ELEMENT_H2,
						ELEMENT_BLOCKQUOTE,
					],
				},
			},
		}),
		createFontSizePlugin(),
		createAutoformatPlugin({
			options: {
				rules: autoformatRules,
				enableUndoOnDelete: true,
			},
		}),
		createResetNodePlugin({
			options: {
				rules: [
					{
						...resetBlockTypesCommonRule,
						hotkey: "Enter",
						predicate: isBlockAboveEmpty,
					},
					{
						...resetBlockTypesCommonRule,
						hotkey: "Backspace",
						predicate: isSelectionAtBlockStart,
					},
				],
			},
		}),
		createDeserializeDocxPlugin({ options: { stripWhitespace: false } }),
		createDeserializeCsvPlugin({ options: { stripWhitespace: false } }),
		createDeserializeMdPlugin({ options: { stripWhitespace: false } }),
		{
			withOverrides: withEnterKeyHandler,
		},
	],
	{
		components: {
			[ELEMENT_UL]: withProps(ListElement, { variant: "ul" }),
			[ELEMENT_OL]: withProps(ListElement, { variant: "ol" }),
			[ELEMENT_LI]: ListItemElement,
			[ELEMENT_BLOCKQUOTE]: BlockquoteElement,
			[ELEMENT_LINK]: LinkElement,
			[ELEMENT_H1]: withProps(HeadingElement, { variant: "h1" }),
			[ELEMENT_H2]: withProps(HeadingElement, { variant: "h2" }),
			[ELEMENT_PARAGRAPH]: ParagraphElement,
			[MARK_BOLD]: withProps(PlateLeaf, { as: "strong" }),
			[MARK_ITALIC]: withProps(PlateLeaf, { as: "em" }),
		},
	},
)
interface DocumentTextAreaProps
	extends DetailedHTMLProps<
		React.HTMLAttributes<HTMLDivElement>,
		HTMLDivElement
	> {
	text?: string
	status?: string
	type: LegalDocumentType
	id: string
	disabled?: boolean
}
function NewDocumentTextArea({
	id,
	text,
	type,
	status,
	disabled,
}: DocumentTextAreaProps) {
	const [content, setContent] = useState(" ")
	const updateDocumentMutation = useLegalDocumentContentMutation(id, type)
	const editorRef = useRef<PlateEditor | null>(null)
	const editor = createPlateEditor({ plugins })
	const textRef = useRef<HTMLDivElement>(null)
	const processingTextClasses =
		status === "processing" ? "leading-6 text-brand-gray-2" : ""

	const isInitialMount = useRef(true)
	const [textIsCopied, setTextIsCopied] = useState(false)
	const initialValue = deserializeMd(editor, text ?? "").map(
		(node: TElement) => ({
			type: node.type ?? ELEMENT_PARAGRAPH,
			children:
				!node.children || node.children.length === 0
					? [{ text: "" }]
					: node.children,
		}),
	)

	useEffect(() => {
		if (textIsCopied) setTimeout(() => setTextIsCopied(false), 3000)
	}, [textIsCopied])

	const debouncedOnChange = _.debounce((value) => {
		const payload = { text: value }
		updateDocumentMutation.mutate({
			id,
			payload,
		})
	}, 500)

	useEffect(() => {
		if (isInitialMount.current) {
			isInitialMount.current = false
		} else {
			debouncedOnChange(content)
		}

		return () => {
			debouncedOnChange.cancel()
		}
	}, [content])

	useEffect(() => {
		if (editor && text) {
			return setContent(text)
		}
	}, [text, editorRef.current])

	return (
		<TooltipProvider>
			<Plate
				plugins={plugins}
				editorRef={editorRef}
				initialValue={initialValue}
				onChange={() => {
					setContent(serializeMd(editorRef.current))
				}}
			>
				<Editor
					ref={textRef}
					className={`  w-full px-[113px] py-[95px] bg-white  overflow-hidden mb-5 text-justify shadow-md mr-6 outline-none !focus-visible:ring-0 !focus-visible:ring-ring !focus-visible:ring-offset-0 ${processingTextClasses} font-cambria`}
					value={content}
					readOnly={status === "processing" || disabled}
					disableDefaultStyles
					renderLeaf={(props) => (
						<PlateLeaf {...props} editor={editor} style={{}} />
					)}
				/>

				<FloatingToolbar>
					<FloatingToolbarButtons />
				</FloatingToolbar>
			</Plate>
		</TooltipProvider>
	)
}
export default NewDocumentTextArea
